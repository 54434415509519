import React, { useState, useEffect } from "react";
import Nav from "../components/Main/Nav";
import FootballForm from "../components/Forms/FootballForm";
import MatchOptions from "../components/Main/MatchOptions";
import FooterNav from "../components/Main/FooterNav";

function MatchListings() {
  return (
    <div style={styles.container}>
      {/* Navigation */}
      <Nav colored={true} />

      <div className="flex flex-col justify-center items-center">
        <FootballForm />
        <MatchOptions />
        <FooterNav />
      </div>
    </div>
  );
}

export default MatchListings;

const styles = {
  container: {
    // backgroundColor: 'red',
  },

  tabs: {
    position: "absolute",
    top: 85,
  },
};
