import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import ToIcon from '@mui/icons-material/East';
import EntertainmentIcon from '@mui/icons-material/LocalCafe';
import Carousel from 'react-material-ui-carousel'
import { Button, Grid, Paper } from "@mui/material";
import Primary from "../Buttons/Primary";
import Bold from "../Text/Bold";
import Regular from "../Text/Regular";
import Light from "../Text/Light";
import { NumberFormatter } from "../fx/fx";
import Submit from "../Buttons/Submit";
import Tiketika from "../Axios/AxiosConfig";
import { useDispatch } from "react-redux";

function PopularEntertainmentCarousel() {
  const theme = useTheme();
  const dispatch = useDispatch();


  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  var lugha = JSON.parse(sessionStorage.getItem("lugha"));

  
  async function getSpot(entertainmentID) {
    console.log(entertainmentID)
  }

  // an array of spots
  const spots = [
    {
      background: "https://tiketika.co.tz/img/entertainment/tips-mikocheni/tips-mikocheni-1.jpg",
      mahali: "Tips Lounge - Miko",
      entertainmentID: "4",
      price: "12000",
      width: "100%",
    },

    {
      background: "https://tiketika.co.tz/img/entertainment/kidimbwi-beach/kidimbwi-beach-scenery-1.jpeg",
      mahali: "Kidimbwi Beach",
      entertainmentID: "3",
      price: "8000",
      width: "100%",
    },

    {
      background: "https://tiketika.co.tz/img/entertainment/tips-coco-beach/tips-coco-beach-1.jpg",
      mahali: "Tips Lounge - Coco",
      entertainmentID: "5",
      price: "9000",
      width: "100%",
    },
  ];

  const Item = (props) => {
    const { item } = props
    let paperStyle = {
      background: `linear-gradient(to bottom, rgba(50, 50, 50, 0), rgba(50, 50, 50, 1)), url(${item.background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '400px',
    }

    let contentStyle = {
      position: 'absolute',
      bottom: 10,
      padding: 8,
      width: smallScreen ? '100%':'33%',
      textAlign: 'left',
    }
    // let route = (<>{item.kutoka} <ToIcon /> {item.kuenda}</>)

    return (
      <Paper elevation={2} style={paperStyle}>
          <div style={contentStyle}>
            <Grid container>
              <Grid item xs={8} sm={8}>
                <Bold text={item.mahali} fontSize={'1.2rem'} color={'#FFF'} /> 
              </Grid>
              <Grid item xs={4} sm={4} style={{ textAlign: 'right', paddingRight: '1rem' }}>
                <EntertainmentIcon style={{ color: '#FFF', textAlign: 'right' }} />
              </Grid>
            </Grid>
            
            <br />

            <Grid container>
              <Grid item xs={7} sm={7}>
                <Light text={lugha.from} fontSize={'0.7rem'} color={'#FFF'} /> <br />
                <Regular text={'TZS ' + NumberFormatter(item.price)} fontSize={'1.2rem'} />
              </Grid>
              <Grid item xs={5} sm={5} style={{ display: 'flex' }}>
                <Submit
                  text={lugha.hotelCTA.toLocaleUpperCase()}
                  // action={() => { getSpot(item.entertainmentID)}}
                  size={'small'}
                  pathTo={"/hot-spot/" + item.entertainmentID}
                />
              </Grid>
            </Grid>
          </div>

      </Paper>
    )
  }
  
  let carouselContent;

  if (smallScreen) {
    // Render individual Paper components for each slide on small screens
    carouselContent = spots.map((item, i) => (
      <Item key={i} item={item} />
    ));
  } 
  else {
    // Calculate the number of slides for larger screens
    const slidesPerPage = Math.ceil(spots.length / 3);

    // Render slides based on the calculated number using Grid
    carouselContent = [...Array(slidesPerPage)].map((_, index) => (
      <Grid container spacing={2} key={index}>
        {spots.slice(index * 3, (index + 1) * 3).map((item, i) => (
          <Grid item xs={4} key={i}>
            <Item item={item} />
          </Grid>
        ))}
      </Grid>
    ));
  }

  return (
    <>
      <div className="text-center text-xl py-8">
        <Bold
          fontSize={smallScreen ? 26 : 36}
          text={lugha.entertainmentPopular}
          color={"#333"}
        />
      </div>

      <div style={{ width: '80%', margin: '0 auto' }}>
        <Carousel autoPlay stopAutoPlayOnHover navButtonsAlwaysVisible
          animation="slide" 
          swipe interval={50000} 
          duration={500} 
          slidesPerPage={3}
          navButtonsProps={{ 
            style: {
              backgroundColor: '#2DA148',
            }
          }} 
        >
          {carouselContent}
        </Carousel>
      </div>
    </>
  );

}

export default PopularEntertainmentCarousel;

{
  /* <img src={spots[3].url} alt={spots[3].title} style={{ width: '100%' }} /> */
}
