

//Swahili Text
const swahili = {
    lugha: 'swahili',
    //Forms
    logInButton: 'Ingia',
    registerButton: 'Jisajili',
    logInText: 'Karibu',
    profileText: 'Karibu Tena',
    registerText: 'Jiunge',
    name: 'Jina',
    email: 'Barua Pepe',
    phone: 'Simu',
    payPhone: 'Namba ya Simu ya Malipo',
    password: 'Nywila',
    rPassword: 'Rudia Nywila',
    optional: 'sio lazima',
    idType: 'Aina ya Kitambulisho',
    idNumber: 'Namba ya Kitambulisho',
    select: 'Chagua',
    age: 'Umri',
    gender: 'Jinsia',
    genderOptions: [{ value: "Mwanaume" }, { value: "Mwanamke" }],
    idOptions: [{ value: "NIDA" }, { value: "Leseni ya Udereva" }, { value: "Pasipoti" }, { value: "Mpiga kura" }],
    ageOptions: [{ value: "Mtu Mzima" }, { value: "Mtoto" }, { value: "Mzee" }],
    checkout: 'Lipa Hapa',
    payNow: 'Lipa Hapa',
    total: 'Jumla',
    add: 'Chagua',
    region: 'Mkoa',
    address: 'Anuani',
    date: 'Tarehe',
    time: 'Muda',
    class: 'Class',
    boarding: 'Kuondoka Kituoni',

    //Navigation
    navBus: 'Bus',
    navRestaurant: 'Migahawa',
    navHotel: 'Hoteli',
    navEvent: 'Matukio',
    navEntertainment: 'Burudani',
    navTrain: 'SGR',
    navFerry: 'Ferry',
    navCinema: 'Sinema',
    navTour: 'Utalii',
    navFlight: 'Ndege',
    navFootball: 'Soka',

    //Intro
    introBus: 'Safari yenye furaha, anza na Tiketika',
    // introBus: 'Basi linakusubiri, twende safarini',
    introEvent: 'Kila siku ni shangwe ukiwa na Tiketika',
    introEntertainment: 'Starehe ya kipekee na Tiketika',
    introTour: 'Safiri Tanzania na visiwa pamoja nasi',
    introFlight: 'Safari yenye furaha, anza na Tiketika',
    introHotel: 'Hoteli bora na hali ya juu na Tiketika',
    introFootball: 'Shabikia timu yako na Tiketika',

    // Footer
    contactUs: 'Wasiliana Nasi',
    terms: 'Vigezo na Masharti',
    privacy: 'Sera za Faragha',
    support: 'Usaidizi',
    supportMessage: 'Tafadhali ingiza kumbukumbu namba ya tiketi yako',
    ticketMessage: 'Tafadhali ingiza namba ya tiketi yako',
    showReferenceMessage: 'Pokea taarifa za kumbukumbu namba yako',
    reference: 'Kumbukumbu Namba',
    powered: 'Kwa Hisani ya',
    call: 'Piga Simu',
    message: 'Ujumbe',
    sendMessage: 'Tuma Ujumbe',
    advertisement: 'Tangazo',
    
    //Search
    from: 'Kuanzia',
    to: 'Kuenda',
    search: 'Tafuta',

    //Search Results
    availableBuses: 'Mabasi ya tarehe:',
    availableRestaurants: 'Migahawa iliyopo:',
    availableHotels: 'Hoteli zenye vyumba tarehe:',
    
    //Bus Route
    busOrigin: 'Kutoka',
    busDestination: 'Kuenda',
    busDeparture: 'Tarehe ya Safari',
    busReturn: 'Tarehe ya Kurudi',
    busPopular: 'Njia za Kawaida',

    //Bus Search Result
    plate: 'Plate Na.',
    departure: 'Muda wa Kuondoka',
    arrival: 'Muda wa Kuwasili',
    pickUp: 'Kutoka',
    dropOff: 'Kuenda',
    seats: 'Viti',
    book: 'Kata Tiketi',
    menu: 'Menu',
    reservation: 'Chagua Meza',
    packages: 'Vifurushi',
    view: 'Pitia',
    seatMap: 'Meza na Viti',
    itinerary: 'Maelezo ya Safari',
    gallery: 'Picha',
    
    //Book Seats
    fare: 'Nauli',
    location: 'Mahali',
    restaurantCTA: 'Chagua',
    hotelCTA: 'Chagua',
    selectSeats: 'Chagua Viti',
    bookedSeats: 'Umechagua Viti',
    busDetails: 'Taarifa za Basi',
    pickUpLocation: 'Kituo cha Kuanzia',
    dropOffLocation: 'Kituo Unaposhuka',
    continue: 'Endelea',
    busFront: 'Upande wa Mbele',
    driverSeat: 'Kiti cha Dereva',
    passengerDetails: 'Taarifa za abiria',
    fillDetails: 'Tafadhali jaza taarifa za abiria',
    ticketDetails: 'Taarifa za tiketi',
    
    //Flight Route
    flightOrigin: 'Kutoka',
    flightDestination: 'Kuenda',
    flightDeparture: 'Tarehe ya Safari',
    flightReturn: 'Tarehe ya Kurudi',
    passengersAndClasses: 'Abiria na Viwango',
    flightClasses: 'Viwango',
    flightPassengers: 'Idadi ya Wasafiri',
    flightPopular: 'Safari za Kawaida',

    //Restaurant Route
    restaurantLocation: 'Chagua Mahali',
    restaurantArrivalDate: 'Tarehe ya Kuwasili',
    restaurantArrivalTime: 'Muda wa Kuwasili',
    restaurantPopular: 'Migahawa Pendwa',

    //Entertainment Route
    entertainmentPopular: 'Kumbi za Starehe',
    entertainmentArrivalDate: 'Tarehe',

    //Hotel Route
    hotelLocation: 'Chagua Mahali',
    hotelCheckIn: 'Tarehe ya Kuwasili',
    hotelCheckOut: 'Tarehe ya Kuondoka',
    hotelPopular: 'Hoteli Maarufu',
    hotelRooms: 'Vyumba vya Hoteli',

    //Event Route
    eventType: 'Tukio la namna gani?',
    eventLocation: 'Chagua Mkoa',   //region level for starters
    eventPopular: 'Matukio Maarufu',

    //Competition Route
    competitionLeague: 'Chagua Ligi',
    competitionPopular: 'Mechi Maarufu',
    matchInfo: 'Pitia ramani ya viti na uchague unapotaka kukaa kuangalia mechi',

    //Event Search Results
    viewTickets: 'Nunua Tiketi',
    price: 'Price',
    
    //Train Route
    trainOrigin: 'Kutoka',
    trainDestination: 'Kuenda',
    trainDeparture: 'Tarehe ya Safari',
    trainReturn: 'Tarehe ya Kurudi (sio lazima)',
    trainPopular: 'Njia Pendwa',
    
    //Ferry Route
    ferryOrigin: 'Kutoka',
    ferryDestination: 'Kuenda',
    ferryDeparture: 'Tarehe ya Safari',
    ferryReturn: 'Tarehe ya Kurudi (sio lazima)',
    ferryPopular: 'Ferry Pendwa',

    //Cinema Route
    cinemaLocation: 'Chagua Mkoa',
    cinemaGenre: 'Chagua aina (sio lazima)',
    cinemaMovie: 'Chagua Sinema',
    cinemaDate: 'Tarehe',
    cinemaPopular: 'Sinema Mpya',

    //Tour Route
    tourType: 'Ungependa kutembelea wapi?',
    tourDate: 'Tarehe',   //region level for starters
    tourPopular: 'Sehemu za Kutalii',

    //Tickets
    ticket: 'tiketi',
    myTickets: 'Tiketi Zangu',
    logOut: 'Log Out',
    profile: 'Taarifa',
    ticketSearch: 'TAFUTA TIKETI',
}

//English Text
const english = {
    lugha: 'english', 
    //Forms
    logInButton: 'Log In',
    registerButton: 'Sign Up',
    logInText: 'Log In',
    profileText: 'Welcome Back',
    registerText: 'Register',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    payPhone: 'Mobile Money Phone Number',
    password: 'Password',
    rPassword: 'Repeat Password',
    optional: 'optional',
    idType: 'ID Type',
    idNumber: 'ID Number',
    select: 'Select',
    age: 'Age',
    gender: 'Gender',
    genderOptions: [{ value: "Male" }, { value: "Female" }],
    idOptions: [{ value: "Driving License" }, { value: "NIDA" }, { value: "Passport" }, { value: "Voter ID" }],
    ageOptions: [{ value: "Adult" }, { value: "Child" }, { value: "Senior" }],
    checkout: 'Checkout',
    payNow: 'Pay Now',
    total: 'Total',
    add: 'Add',
    region: 'Region',
    address: 'Address',
    date: 'Date',
    time: 'Time',
    class: 'Class',
    boarding: 'Boarding Time',

    //Navigation
    navBus: 'Buses',
    navRestaurant: 'Restaurants',
    navHotel: 'Hotels',
    navEvent: 'Events',
    navEntertainment: 'Hot Spots',
    navTrain: 'SGR',
    navFerry: 'Ferries',
    navCinema: 'Cinemas',
    navTour: 'Tours',
    navFlight: 'Flights',
    navFootball: 'Football',

    //Intro
    introBus: 'Journey with ease, tickets in a breeze',
    introEvent: 'Unforgettable moments, one ticket away',
    introEntertainment: 'Looking for a hot spot, lounge or restaurant?',
    introTour: 'Travel through Tanzania with Tiketika',
    introFlight: 'Book flights across the world with Tiketika',
    introHotel: 'Discover top-notch hotels with Tiketika',
    introFootball: 'Book your ticket and Support your team',

    // Footer
    contactUs: 'Contact Us',
    terms: 'Terms & Conditions',
    privacy: 'Privacy Policy',
    support: 'Support',
    supportMessage: 'Please enter your ticket reference number',
    ticketMessage: 'Please enter your ticket number',
    showReferenceMessage: 'Here is your booking information',
    reference: 'Reference Number',
    powered: 'Powered by',
    call: 'Call Us',
    message: 'Message',
    sendMessage: 'Send Message',
    advertisement: 'Advertisement',
    
    //Search
    from: 'From',
    to: 'To',
    search: 'Search',
    
    //Search Results
    availableBuses: 'Buses available on:',
    availableRestaurants: 'Restaurants available:',
    availableHotels: 'Hotel rooms available on:',
    
    //Bus Route
    busOrigin: 'Select Origin',
    busDestination: 'Select Destination',
    busDeparture: 'Departure Date',
    busReturn: 'Return Date',
    busPopular: 'Popular Routes',
    
    //Bus Search Result
    plate: 'Plate No.',
    departure: 'Departure Time',
    arrival: 'Arrival Time',
    pickUp: 'Pick-up Location',
    dropOff: 'Drop-off',
    seats: 'Seats',
    book: 'Book Now',
    menu: 'Menu',
    reservation: 'Make a Reservation',
    packages: 'Packages',
    view: 'View',
    seatMap: 'Seat Map',
    itinerary: 'Itinerary',
    gallery: 'Gallery',
        
    //Book Seats
    fare: 'Fare',
    location: 'Location',
    restaurantCTA: 'Add',
    hotelCTA: 'Book',
    selectSeats: 'Select Seats',
    bookedSeats: 'Booked Seats',
    busDetails: 'Bus Details',
    pickUpLocation: 'Pick Up Location',
    dropOffLocation: 'Drop-off Location',
    continue: 'Continue',
    busFront: 'Bus Front',
    driverSeat: 'Driver\'s Seat',
    passengerDetails: 'Passenger Details',
    fillDetails: 'Fill in the details below',
    ticketDetails: 'Details for ticket',
    
    //Flight Route
    flightOrigin: 'Origin',
    flightDestination: 'Destination',
    flightDeparture: 'Departure',
    flightReturn: 'Return',
    passengersAndClasses: 'Passengers & Classes',
    flightClasses: 'Travel Class',
    flightPassengers: 'Travellers',
    flightPopular: 'Most Common Flights',

    //Restaurant Route
    restaurantLocation: 'Where To?',
    restaurantArrivalDate: 'Arrival Date',
    restaurantArrivalTime: 'Arrival time',
    restaurantPopular: 'Popular Restaurants',

    //Entertainment Route
    entertainmentPopular: 'Popular Entertainment Spots',
    entertainmentArrivalDate: 'Date',

    //Hotel Route
    hotelLocation: 'Where To?',
    hotelCheckIn: 'Check-In Date',
    hotelCheckOut: 'Check-Out Date',
    hotelPopular: 'Popular Hotels',
    hotelRooms: 'Hotel Rooms',

    //Event Route
    eventType: 'What are you interested in?',
    eventLocation: 'Select a Region',   //region level for starters
    eventPopular: 'Trending Events',

    //Competition Route
    competitionLeague: 'Choose a League',
    competitionPopular: 'Popular Matches',
    matchInfo: 'Check out the seatmap and select your seat for the match',

    //Event Search Results
    viewTickets: 'View Tickets',
    price: 'Bei',
    
    //Train Route
    trainOrigin: 'Select Origin',
    trainDestination: 'Select Destination',
    trainDeparture: 'Departure Date',
    trainReturn: 'Return Date (optional)',
    trainPopular: 'Popular Routes',
    
    //Ferry Route
    ferryOrigin: 'Select Origin',
    ferryDestination: 'Select Destination',
    ferryDeparture: 'Departure Date',
    ferryReturn: 'Return Date (optional)',
    ferryPopular: 'Popular Routes',

    //Cinema Route
    cinemaLocation: 'Select Region',
    cinemaGenre: 'Pick a genre (optional)',
    cinemaDate: 'Preferred Date',
    cinemaMovie: 'Pick a movie',
    cinemaPopular: 'Epic New Movies',

    //Tour Route
    tourType: 'What are you interested in?',
    tourDate: 'Date (optional)',   //region level for starters
    tourPopular: 'Trending Events',

    //Tickets
    ticket: 'ticket',
    myTickets: 'My Tickets',
    logOut: 'Log Out',
    profile: 'Profile',
    ticketSearch: 'SEARCH TICKET',
}

export {swahili, english}