import React, { useState, useEffect } from 'react'
import Primary from '../Buttons/Primary';
import Button from '@mui/material/Button';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '15px',
    width: 400,
    boxShadow: 24,
    p: 4,
};

const useStyles = makeStyles((theme) => ({

    basic: {
        padding: 20,
        borderRadius: 15,
        width: 300,
        border: "none",
        outline: "none",
        margin: 5,
        fontFamily: "Euclid-Bold",
        color: '#ffffff',
        background: '#252525',
        boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            width: '78vw',
        },

    },
    formInput: {
        fontSize: 14, 
        textAlign: 'left', 
        fontFamily: "Euclid-Bold", 
        color: '#ffffff',
        position: 'relative',
        left: '2rem',
        top: '0.8rem',
    }

}));
  

//   Sign up function using axios
export default function Profile({Profile}) {
    console.log(sessionStorage.getItem('name'))
    const classes = useStyles();
    const [name, setName] = useState(sessionStorage.getItem('name'));
    const [email, setEmail] = useState(sessionStorage.getItem('email'));
    const [phone, setPhone] = useState(sessionStorage.getItem('phone'));
    const [password, setPassword] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [generalError, setGeneralError] = useState('');
    const [generalSuccess, setGeneralSuccess] = useState('');
    
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    
    const handleName = (e) => {
        setName(e.target.value);
    }
    
    const handlePassword = (e) => {
        setPassword(e.target.value);
    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handlePhone = (e) => {
        setPhone(e.target.value);
    }
    

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            name: name,
            email: email,
            phone: phone,
            password: password,
        }

        axios.post('https://tiketika.co.tz/api/authenticate/login/', data)
            .then(res => {
                if(res.data.name){
                    window.sessionStorage.setItem('name', res.data.name)
                    window.sessionStorage.setItem('email', res.data.email)
                    window.sessionStorage.setItem('phone', res.data.phone)
                    window.sessionStorage.setItem('token', res.data.token)
                    setGeneralSuccess(res.data.message)

                    setTimeout(() => {
                        Profile();
                        document.location.reload();
                    }, 1000);
                }
                else{
                    setGeneralError(res.data.message)
                }
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
        
    }
    //declare lugha
    var lugha = JSON.parse(window.sessionStorage.getItem('lugha'))
  
    const errorStyles = {
        width: 'inherit',
        height: '50px',
        //left: matches ? '' : '6rem',
        position: 'absolute',
        borderRadius: '15px',
        padding: '4px 8px',
        top: '1rem',
        backgroundColor: '#D84646',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        '-webkit-box-shadow': '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        '-moz-box-shadow': '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        zIndex: '1000'
    }

    const successStyles = {
        width: 'inherit',
        height: '50px',
        //left: matches ? '' : '6rem',
        position: 'absolute',
        borderRadius: '15px',
        padding: '4px 8px',
        top: '1rem',
        backgroundColor: '#2DA148',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        '-webkit-box-shadow': '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        '-moz-box-shadow': '-2px 6px 15px 0px rgba(51,51,51,0.43)',
        zIndex: '1000'
    }
        
    useEffect(() => {
        setTimeout(() => {
            setGeneralError('');
        }, 3000)
    }, [generalError])
            
    useEffect(() => {
        setTimeout(() => {
            setGeneralSuccess('');
        }, 3000)
    }, [generalSuccess])

    return (

        <form onSubmit={handleSubmit} style={{ flexDirection: 'row', padding: '5px 12px', color: '#ffffff' }}>
            {/* Error div */}
            {generalError && <div style={errorStyles}>{generalError}</div>}
            
            {/* Success div */}
            {generalSuccess && <div style={successStyles}>{generalSuccess}</div>}

            <div><h1>{lugha.profileText}</h1></div>

            <div>
                <label>
                    <span className={classes.formInput}>{lugha.name}</span> <br />
                    <input required type="text" className={classes.basic} name="name" placeholder={sessionStorage.getItem('name')} disabled = {disabled} onChange={handleName} />
                </label>
            </div>

            <div>
                <label>
                    <span className={classes.formInput}>{lugha.email}</span> <br />
                    <input required type="email" className={classes.basic} name="email" placeholder={sessionStorage.getItem('email')} disabled = {disabled} onChange={handleEmail} />
                </label>
            </div>

            <div>
                <label>
                    <span className={classes.formInput}>{lugha.phone}</span> <br />
                    <input required type="phone" className={classes.basic} name="phone" placeholder={sessionStorage.getItem('phone')} disabled = {disabled} onChange={handlePhone} />
                </label>
            </div>

            {/* <div>
                <Button>
                    <Primary text={lugha.logInButton} />
                </Button>
            </div> */}
            

        </form>

    )
}