import React, { useState, useEffect } from "react";
import Bold from "../Text/Bold";
import Light from "../Text/Light";
import Secondary from "../Buttons/Secondary";
import Primary from "../Buttons/Primary";
import Semi from "../Text/Semi";
import { Link } from "react-router-dom";
import DropInput from "../Inputs/DropInput";
import FInput from "../Inputs/FInput";
import { makeStyles } from "@material-ui/core/styles";

import { useSelector, useDispatch } from "react-redux";
import {
  setCheckoutDetails,
  selectCheckoutDetails,
  selectEntertainmentForm,
  setEntertainmentPackages,
  setEventPackages,
  setTourPackages,
  setHotelRooms,
  selectHotelForm,
} from "../../slices/NavSlice";
import AxiosInstance from "../Axios/AxiosConfig";
import axios from "axios";
import { ConvertUnholyObjectToArray, NumberFormatter } from "../fx/fx";
import { CancelOutlined } from "@mui/icons-material";
import HotelIcon from '@mui/icons-material/Hotel';
import { Drawer } from "@material-ui/core";
import { CircularProgress, Grid } from "@mui/material";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  sidePanel: {
    position: "fixed",
    overflowY: "auto",
    bottom: 0,
    right: 0,
    backgroundColor: "#fff",
    width: "50%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 5,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  drawer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50vw",
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  }
}));

function Checkout({
  text,
  type,
  currency,
  action,
  items,
  closeAction,
  session,
  total,
  details,
  pathTo,
}) {
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));
  const classes = useStyles();
  const dispatch = useDispatch();

  
  const consolidateTiers = (items) => {
    const consolidatedItems = {};
    if(type === 'event') {
    
      items.forEach((item) => {
        const tierID = item.tier.tierID;
    
        if (consolidatedItems[tierID]) {
          consolidatedItems[tierID].quantity += item.quantity;
        } else {
          consolidatedItems[tierID] = { ...item };
        }
      });
    
      return Object.values(consolidatedItems);
    }
    else{
      return false
    }
  }

  const consolidateTourPackages = (items) => {
    const consolidatedItems = {};
    
    if(type === 'tour') {
      items.forEach((item) => {
        const packageID = item.package.packageID;
    
        if (consolidatedItems[packageID]) {
          consolidatedItems[packageID].quantity += item.quantity;
        } else {
          consolidatedItems[packageID] = { ...item };
        }
      });
      return Object.values(consolidatedItems);
    }
    else{
      return false
    }
    
  }

  const consolidateRooms = (items) => {
    const consolidatedItems = {};
    if(type === 'hotel') {
    
      items.forEach((item) => {
        const roomID = item.room.roomID;
    
        if (consolidatedItems[roomID]) {
          consolidatedItems[roomID].quantity += item.quantity;
        } else {
          consolidatedItems[roomID] = { ...item };
        }
      });
    
      return Object.values(consolidatedItems);
    }
    else{
      return false
    }
  }

  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState('M Pesa');
  const [paymentDetails, setPaymentDetails] = useState([ { "item": "Tuma Pesa" }, { "item": "Lipa Bili" }, { "item": "Malipo ya Serikali" }, { "item": "Ingiza namba x" }, { "item": "Kumbukumbu nambari y" }, { "item": "PIN" } ]);
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [payPhone, setPayPhone] = useState('')
  const [entertainmentItems, setEntertainmentItems] = useState(items)
  const [tourItems, setTourItems] = useState(consolidateTourPackages(items))
  const [eventItems, setEventItems] = useState(consolidateTiers(items))
  const [hotelItems, setHotelItems] = useState(consolidateRooms(items))

  const hotelGlobalState = useSelector(selectHotelForm);
  const entertainmentGlobalState = useSelector(selectEntertainmentForm);
  
//function to get the payment details for the selected payment method for a particular session
  const getBusPaymentDetails = async () => {
    try {
      
      let typeID = window.sessionStorage.getItem('typeID')
      const { seats, pickUpDropOff } = items
      let bus = {
        ...items.bus,
        pickUp: pickUpDropOff.pickUp,
        dropOff: pickUpDropOff.dropOff,
      }
      let passengers = ConvertUnholyObjectToArray(items.passengers)

      const book = await AxiosInstance.post('booking/?type=bus', { bus, seats, passengers, typeID, headers:  { "Access-Control-Allow-Origin": "*" } });
      console.log('book: ', book.data)

      if(book.data.status === '00'){
        const { message, reference, status } = book.data
        
        //This is to create our jwt to sign our payload
        const token = await axios.get('https://tiketika.co.tz/.well-known/tz-payment-ticketing/jwt/?reference='+reference, { headers:  { "Access-Control-Allow-Origin": "*" } });
        const jwt = token.data
        console.log(jwt)
        
        //Get payload to send to UTS
        const data = await axios.get('https://tiketika.co.tz/.well-known/tz-payment-ticketing/?id='+reference, { headers:  { "Access-Control-Allow-Origin": "*" } });
        const payload = data.data
        console.log(payload)
        




        
        // // Perform POST request to cash_payment_url with payload and jwk
        // const cashPaymentResponse = await axios.post('http://41.59.227.19/ticket_mtandao/api/cash-payment', payload, { headers: { Authorization: `Bearer ${jwt}` } });
        // const response = cashPaymentResponse.data
        // console.log(cashPaymentResponse);
        
        // if(response.status_code === "00"){
        //   //Update ticket status
        //   const accept = await AxiosInstance.post('acceptance/?type=bus', { reference, headers:  { "Access-Control-Allow-Origin": "*" } });
        //   console.log('accept: ', accept.data)

        //   //Print receipt if cash payment accepted and typeID <= 3
          

        // }
        // else{
        //   //Processing failed on the UTS

        // }









        
        // const cashPaymentResponse = await AxiosInstance.post('payment/uts/web/', { data, headers:  { keys } });
        // Update token and log response - This will likely be done server side - 31072023
        // setUTSEndpoints(cashPaymentResponse.data);
        
      }
      else{
        console.log(book.data.message)
      }

    } catch (error) {
      console.log(error);
    }
  }
  
  const EventListItems = ({ items }) => {
    let tiers = consolidateTiers(items)
    return (
      <>
      {tiers.map((item, index) => {
        const { tier, quantity } = item

        return (
          <div style={{ padding: 4 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Primary text={quantity +' '+ tier.tier} />
              <div>
                <Bold text={tier.event} /><br />
                <Bold text={lugha.price} />: <Semi text={currency + ' ' +  NumberFormatter(tier.price*quantity)} />
              </div>
            </div>
            {/* <div style={styles.separator}></div> */}
          </div>
        );
      })}
      </>
    )
  }
  
  const EntertainmentListItems = ({ items }) => {
    // let packages = consolidatePackages(items)
    let packages = []
    
    console.log(items)
    return (
      <>
        <div style={styles.separator}></div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Bold text={'Reserve tables on:'} /><br />
            <Bold align={'center'} text={entertainmentGlobalState.date ? `${moment(entertainmentGlobalState.date).format('Do MMM, YYYY')}` : ''} />
          </Grid>

          <Grid item xs={12} sm={6}>
            {items.map((item, index) => (
              <div
                key={index}
                className={`inline-block rounded border bg-green-500 border-green-400 mx-3 my-2 px-3 py-1.5 sm:px-2 sm:py-1 xl:px-4 xl:py-2 text-sm font-medium text-white hover:bg-transparent hover:text-gray-400 focus:outline-none focus:ring active:text-indigo-500 shadow-gray-300 shadow-lg hover:cursor-pointer`}
              >
                <p>{item}</p>
              </div>
            ))}
          </Grid>
        </Grid>

      </>
    )
  }
  
  const MatchListItems = ({ items }) => {
    
    return (
      <>
      {items.map((item, index) => {
        const { tier, quantity } = item

        return (
          <div style={{ padding: 4 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Primary text={quantity +' '+ tier.tier} />
              <div>
                <Bold text={lugha.price+': '} /><Semi text={currency + ' ' +  NumberFormatter(parseInt(tier.price) * quantity)} />
              </div>
            </div>
            {/* <div style={styles.separator}></div> */}
          </div>
        );
      })}
      </>
    )
  }
  
  const TourListItems = ({ items }) => {
    let packages = consolidateTourPackages(items)
    
    return (
      <>
      {packages.map((item, index) => {
        // console.log(item)
        const { quantity } = item
        const { entertainment, price, packageType, ainaYaBurudani } = item.package
        let type = packageType
        if(lugha.lugha === 'swahili'){
          type = ainaYaBurudani
        }
        return (
          <div style={{ padding: 4 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Primary text={quantity +' '+ item.package.package} />
              <div>
                <Bold text={entertainment} /><br />
                {/* <Bold text={type} /><br /> */}
                <Semi text={currency + ' ' + NumberFormatter(price*quantity)} />
              </div>
            </div>
            {/* <div style={styles.separator}></div> */}
          </div>
        );
      })}
      </>
    )
  }
  
  const HotelListItems = ({ items }) => {
    let rooms = consolidateRooms(items)
    
    return (
      <>
      {rooms.map((item, index) => {
        // console.log(item)
        const { quantity } = item
        const { hotel, room, price, roomType, ainaYaChumba } = item.room
        let type = roomType
        if(lugha.lugha === 'swahili'){
          type = ainaYaChumba
        }
        return (
          <div style={{ padding: 4 }}>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Primary text={quantity +' '+ room} />
              <div>
                <Bold text={hotel} /><br />
                {/* <Bold text={type} /><br /> */}
                <Semi text={currency + ' ' + NumberFormatter(price*quantity)} />
              </div>
            </div>
          </div>
        );
      })}
      </>
    )
  }

  useEffect(() => {
    
    if(type !== 'bus'){
      dispatch(setCheckoutDetails({name, email, phone, payPhone}));
    }

  }, [name, email, phone, payPhone]); // Run the corresponding getPaymentDetails function on component mount

  useEffect(() => {
    dispatch(setEntertainmentPackages(entertainmentItems))
  }, [entertainmentItems]);

  useEffect(() => {
    dispatch(setEventPackages(eventItems))
  }, [eventItems]);

  useEffect(() => {
    dispatch(setTourPackages(tourItems))
  }, [tourItems]);

  useEffect(() => {
    dispatch(setHotelRooms(hotelItems))
  }, [hotelItems]);

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);

    switch (type) {
      case 'bus':
        await getBusPaymentDetails();      
        break;
    
      default:
        try {
          if (payPhone) {
            await action(e); // Assuming action is an asynchronous function
          } 
          else {
            console.log('Error message for blank payment phone');
          }
        } 
        catch (error) {
          console.error('An error occurred during action:', error);
        }
        finally {
          setLoading(false);
        }

        break;
    }
    
  };

  return (
      <Drawer
        anchor="right"
        open={true}
        onClose={closeAction}
      >
      <div className={classes.drawer}>
        <form style={{ width: '68%' }}>
          {/* Rounded button with gray background */}
          <div
            onClick={closeAction}
            style={{
              cursor: "pointer",
              position: "absolute",
              zIndex: 10,
              right: "3%",
              top: "8%",
              borderRadius: 50,
              backgroundColor: "#f5f5f5",
              width: 50,
              height: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CancelOutlined />
          </div>

          <div style={{ padding: '20 0', textAlign: 'center' }}>
            <Bold text={"Checkout"} fontSize={22} color={"#2BA147"} />
            <br></br>

            {items?.seats && (
              <Bold
                text={"TZS " + NumberFormatter(items.seats.length * items.price)}
                fontSize={28}
              />
            )}
            
            {total && <Bold text={"TZS " + NumberFormatter(total)} fontSize={28} />}
          </div>

          {type === 'event' && 
            <div style={{ paddingBottom: 20, margin: 5, borderRadius: 15, 
              // backgroundColor: "#CBFFB5",
            }}>
              <EventListItems items={items} />
            </div>
          }

          {type === 'entertainment' && 
            <div style={{ paddingBottom: 20, margin: 5, borderRadius: 15, 
              // backgroundColor: "#CBFFB5",
            }}>
              <EntertainmentListItems items={items} />
            </div>
          }

          {type === 'match' && 
            <div style={{ paddingBottom: 20, margin: 5, borderRadius: 15, 
              // backgroundColor: "#CBFFB5",
            }}>
              <MatchListItems items={items} />
            </div>
          }

          {type === 'tour' && 
            <div style={{ paddingBottom: 20, margin: 5, borderRadius: 15, 
              // backgroundColor: "#CBFFB5",
            }}>
              <TourListItems items={items} />
            </div>
          }

          {type === 'hotel' && 
            <div style={{ paddingBottom: 20, margin: 5, borderRadius: 15, 
              // backgroundColor: "#CBFFB5",
            }}>
                
              <Grid container>

                <Grid item xs={12} sm={4}>
                  <Bold text={`${moment(hotelGlobalState.checkInDate).format('Do MMM, YYYY')}`} />
                  <br />
                  <Light text={<small>{lugha.hotelCheckIn}</small>} />
                </Grid>

                <Grid item xs={0} sm={4} style={{ textAlign: 'center' }}>
                  <HotelIcon />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Bold text={`${moment(hotelGlobalState.checkOutDate).format('Do MMM, YYYY')}`} />
                  <br />
                  <Light text={<small>{lugha.hotelCheckOut}</small>} />
                </Grid>

              </Grid>

              <div style={styles.separator}></div>
              <HotelListItems items={items} />
            </div>
          }
            
          <div style={{ paddingBottom: 20 }}>
            {/* inputs to accept user details */}
            <div style={{ paddingBottom: 20 }}>

              {/* render user details for non bus bookings...airlines may have bus logic, heads up */}
              {type !== 'bus' &&
                <>
                  <FInput
                    width={'100%'}
                    type={"text"}
                    name={"name"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder= {lugha.name}
                    required
                  />
                  <br />
                  
                  <FInput
                    width={'100%'}
                    type={"text"}
                    name={"phone"}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder={lugha.phone}
                    required
                  />
                  <br />
                  
                  <FInput
                    width={'100%'}
                    type={"email"}
                    name={"email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={lugha.email}
                    // placeholder={lugha.email + " ("+ lugha.optional +")"}
                  />
                </>  
              }
            </div>

            <FInput
              width={'100%'}
              label="Payment Method"
              placeholder="Choose Payment Method"
              options={[
                { id: 1, value: "Enter your Payment Phone Number" },
                // { id: 2, value: "Tigo Pesa" },
                // { id: 3, value: "Airtel Money" },
              ]}
              value={selected}
              isSelect
              onChange={(e) => {
                setSelected(e.target.value);
              }}
            />

            
            <FInput
              width={'100%'}
              type={"text"}
              name={"payPhone"}
              value={payPhone}
              onChange={(e) => setPayPhone(e.target.value)}
              placeholder={lugha.payPhone}
              required
            />

            {type === 'bus' && (
              <Semi
                text={
                  "Payment Details " + (selected != undefined) ? selected : ""
                }
                fontSize={12}
              />
            )}

            {type === 'bus' &&
              paymentDetails.map((item, index) => {
                return (
                  <div key={index} style={{ textAlign: "left" }}>
                    {index + 1}. <Semi text={item.item} fontSize={14} />
                  </div>
                );
              })}
          </div>

          <div
            style={{
              borderRadius: 50,
              width: "100%",
            }}
          >
            {pathTo && (
              <Link to={pathTo} style={{ textDecoration: "none" }}>
                <Primary text={text} />
              </Link>
            )}

            {action && 
            <Primary text={<>{text} {loading ? <CircularProgress size={20} sx={{ color: '#333', position: 'absolute', top: -6, left: -6, zIndex: 1, }} /> : ''}</>} action={(e) => handleClick(e)} style={{ width: 'inherit' }} />
            }
          </div>
        </form>
      </div>
      </Drawer>
    
  );
}

export default Checkout;

const styles = {
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#e0e0e0",
    margin: 10,
  },
};