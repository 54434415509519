/* eslint-disable jsx-a11y/img-redundant-alt */
// a component to render a bus listing in the main page
import React, { useEffect, useState } from "react";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Regular from "../Text/Regular";
import { Dialog, DialogContent, Grid, Paper } from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import Bold from "./../Text/Bold";
import Semi from "./../Text/Semi";
import Light from "./../Text/Light";
import { faCheckCircle, } from "@fortawesome/free-solid-svg-icons";
import Submit from "./../Buttons/Submit";
import BusIcon from '@mui/icons-material/DirectionsBus';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useDispatch, useSelector } from "react-redux";
import {
  selectAvailableBuses,
  selectBusForm,
} from "../../slices/NavSlice";
import { NumberFormatter } from "../fx/fx";

function BusListing({ departureDate }) {
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));
  const availableBuses = useSelector(selectAvailableBuses);
  
  const [displayBuses, setDisplayBuses] = useState(availableBuses)
  const [filterOptions, setFilterOptions] = useState({
    ordinary: false,
    luxury: false,
    semiLuxury: false,
  });

  const busGlobalState = useSelector(selectBusForm);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const [openLoadingModal, setOpenLoadingModal] = useState(true);
  const handleOpenLoadingModal = () => setOpenLoadingModal(true);
  const handleCloseLoadingModal = () => setOpenLoadingModal(false);

  useEffect(() => {
    if(displayBuses && displayBuses.length){
      handleCloseLoadingModal()
    }
  }, [displayBuses])
  
  useEffect(() => {
    if(availableBuses && availableBuses.length){
      const filteredBuses = availableBuses.filter((bus) => {
        if (
          (filterOptions.ordinary && bus.cabin === "Ordinary") ||
          (filterOptions.luxury && bus.cabin === "Luxury") ||
          (filterOptions.semiLuxury && bus.cabin === "Semi Luxury")
        ) {
          return true;
        } 
        else {
          return false;
        }
      });
      
      // If all filter options are false, return the original array
      const noFiltersSelected = Object.values(filterOptions).every((option) => !option);
      const finalBuses = noFiltersSelected ? availableBuses : filteredBuses;
      setDisplayBuses(finalBuses);
    }

  }, [filterOptions, availableBuses])

  const handleCheckboxChange = (option) => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
    
    console.log(filterOptions)
  };

  const checkBoxStyles = {
    color: '#2BA147',
    '&.Mui-checked': {
      color: '#2BA147',
    },
  }

  return (
    
    <Grid container spacing={2}>
      <Grid item xs={12} sm={1}/>

      <Grid item xs={12} sm={3}>
        <div>
          {displayBuses && displayBuses.length && (
            
            <Paper>
              <br />
              <Grid container>
                <Grid item xs={1} sm={1} />
                <Grid item xs={5} sm={5} style={{textAlign: 'left'}}>
                  <Bold text={'Filter'} />
                </Grid>
                <Grid item xs={5} sm={5} style={{textAlign: 'right'}}>
                  <Light text={'Reset'} fontSize={'0.8rem'} color={'#2BA147'} style={{ cursor: 'pointer' }}  />
                </Grid>
                <Grid item xs={1} sm={1} />
              </Grid>

              <AccordionGroup>
                <Accordion expanded>
                  <AccordionSummary><Semi text={"Cabin Type"} color={'#000'} /></AccordionSummary>
                  <AccordionDetails>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox sx={checkBoxStyles} />} onChange={() => handleCheckboxChange("ordinary")} label={<Regular text={"Ordinary"} color={'#000'} />} />
                      <FormControlLabel control={<Checkbox sx={checkBoxStyles} />} onChange={() => handleCheckboxChange("luxury")} label={<Regular text={"Luxury"} color={'#000'} />} />
                      <FormControlLabel control={<Checkbox sx={checkBoxStyles} />} onChange={() => handleCheckboxChange("semiLuxury")} label={<Regular text={"Semi Luxury"} color={'#000'} />} />
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>
              </AccordionGroup>
            </Paper>
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={7}>
        <div
        //   style={{
        //     display: "flex",
        //     flexDirection: "column",
        //     justifyContent: "space-between",
        //     alignItems: "center",
        //     marginBottom: 25,
        //   }}
        >
        
          {/* Loading Dialog */}
          <Dialog
            open={openLoadingModal}
            onClose={handleCloseLoadingModal}
            aria-labelledby={'Loading'}
            aria-describedby={'Loading'}
            maxWidth="sm"
          >
            <DialogContent>
              <CircularProgress />  
            </DialogContent>
          </Dialog>
          
          {displayBuses && displayBuses.length &&
            displayBuses.map((bus, id) => {
              var bus_id = id;
              var bus_plate = bus.plate;
              var bus_date = busGlobalState.date;

              return (
                <section className="mb-4">
                  <div class="max-w-screen-xl mx-auto border-2 bg-white rounded-2xl pb-4 py-8 px-3">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>

                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={4}>
                            <img className={!imageError ? "w-[120px] h-[auto]" : "w-[100px] h-[auto]"} 
                              src={!imageError ? `https://tiketika.co.tz/img/logos/${bus.logo}` : 'https://tiketika.co.tz/assets/images/bus-icon.png'}
                              alt="Bus" 
                              onError={handleImageError}
                            />
                          </Grid>

                          <Grid item xs={8} sm={8}>
                            <div className="flex flex-col justify-center items-start text-left">
                              <Bold fontSize={'1.4rem'} text={bus.name} />
                              <div style={{ padding: 5,borderRadius: 5, width: "100%", }} className="bg-gray-100">
                                <Semi text={lugha.plate + " " + bus.plate} />
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                      </Grid>

                      <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid container>
                          <Grid item xs={4} sm={4}>
                            <div>
                              <Bold text={bus.originAbbreviation} /><br />
                              <Light text={bus.startTime} />
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <div style={{ color: '#9a9a9a' }}>
                              <HorizontalRuleIcon />
                              <BusIcon />
                              <HorizontalRuleIcon />
                              
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <div>
                              <Bold text={bus.destinationAbbreviation} /><br />
                              <Light text={bus.endTime} />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <div style={styles.separator} className="my-2"></div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6}>
                        <div className="w-full h-full rounded-md bg-gray-100 flex flex-col items-center justify-center self-center">
                          <Light text={bus.cabin} fontSize={'0.8rem'} color={'#000'} />
                          <Bold text={ "Tsh. " + NumberFormatter(bus.normalPrice)} fontSize={'1.2rem'} />
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6} className="flex items-center justify-center">
                        <Submit
                          text={lugha.book}
                          iconName={faCheckCircle}
                          pathTo={ "/book-seats/" + bus.plate + "/" + bus.name.toLocaleLowerCase() + "/" + bus_date }
                        />
                      </Grid>
                    </Grid>
                  </div>
                </section>
              );
            })}

          {!displayBuses && displayBuses.length && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: 300,
              }}
            >
              {/* <img src='/images/no-bus.png' alt='No Bus' style={{ width: 200, height: 200 }} />    */}
              <Bold text="No Bus Available" fontSize={20} />
            </div>
          )}
        </div>
      </Grid>

      <Grid item xs={12} sm={1}/>
    </Grid>
  );
}

export default BusListing;

const styles = {
  card: {
    width: 300,
    margin: 80,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    display: "flex",
    justifyContent: "center",
  },
  busDetails: {
    textAlign: "left",
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#e0e0e0",
    // margin: 10,
  },
  priceDetails: {
    padding: 20,
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    width: "20%",
    borderRadius: 30,
  },

  anem: {
    display: "flex",
    alignItems: "center",
    // margin: 5,
  },

  price: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#2BA147",
  },
};
