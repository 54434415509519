import React, { useEffect, useState } from "react";
import Nav from "./../components/Main/Nav";
import BasicTabs from "./../components/Main/Tabx";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import { selectHomeBg, setHomeBg } from "../slices/NavSlice";
import Bold from "../components/Text/Bold";
import AxiosInstance from "../components/Axios/AxiosConfig";

const useStyles = makeStyles((theme) => ({
  welcome: {
    color: "white",
    fontSize: "5.5rem",
    marginTop: "5rem",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {},
  },

  tiketikabg: {
    backgroundImage:
      "url('https://tiketika.co.tz/img/backgrounds/tiketika-web-banner.webp')",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    height: "66%",
    width: "100%",
    position: "absolute",
    bottom: "-29%",
  },

  smallbg: {
    backgroundImage:
      "url('https://tiketika.co.tz/img/backgrounds/tiketika-small-web-banner.webp')",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {},
    width: "100%",
    position: "absolute",
  },

  tiketikaCenteredLogo: {
    backgroundImage:
      "url('https://tiketika.co.tz/img/assets/tiketika-white-shaded-padded-logo.webp')",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      bottom: "44%",
      left: "19%",
      width: "58%",
      height: "24%",
    },
    position: "absolute",
  },
}));

function Home() {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const homeBg = useSelector(selectHomeBg);

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  var backgroundImage;
  var long = false;

  const [tab, setTab] = useState(0);

  const switchTab = (tab) => {
    
    dispatch(setHomeBg(tab));
    setTab(tab)
    
  }

  useEffect(() => {
    dispatch(setHomeBg(0));
  }, []);

  async function getToken() {
    try {
      const response = await AxiosInstance.get("authenticate/token/");
      //console.log(response.data.token);
      window.sessionStorage.setItem("token", response.data.token);
    } catch (error) {
      console.log("error", error);
    }
  }

  if (homeBg === 0) {
    backgroundImage = "url('https://tiketika.co.tz/img/backgrounds/bus-station-1.webp')";
    long = true;
  } 
  else if (homeBg === 1) {
    backgroundImage = "url('https://tiketika.co.tz/img/backgrounds/flights-1.webp')";
    long = false;
  } 
  else if (homeBg === 2) {
    backgroundImage = "url('https://tiketika.co.tz/img/backgrounds/the-residence-zanzibar-1.webp')";
    long = false;
  } 
  else if (homeBg === 3) {
    backgroundImage = "url('https://tiketika.co.tz/img/backgrounds/tours-1.webp')";
    long = true;
  } 
  else if (homeBg === 4) {
    backgroundImage = "url('https://tiketika.co.tz/img/backgrounds/the-residence-zanzibar-1.webp')";
    long = false;
  } 
  else if (homeBg === 5) {
    backgroundImage = "url('https://tiketika.co.tz/img/backgrounds/events-1.webp')";
    long = true;
  } 
  else if (homeBg === 6) {
    backgroundImage = "url('https://tiketika.co.tz/img/backgrounds/football-1.webp')";
    // backgroundImage = "url('https://tiketika.co.tz/img/backgrounds/cinemas-1.webp')";
    long = true;
  } 
  else if (homeBg === 7) {
    backgroundImage = "url('https://tiketika.co.tz/img/backgrounds/sgr-tanzania-1.webp')";
    long = true;
  } 
  else if (homeBg === 8) {
    backgroundImage = "url('https://tiketika.co.tz/img/backgrounds/ferry-2.webp')";
    long = false;
  }
  else if (homeBg === 9) {
    backgroundImage = "url('https://tiketika.co.tz/img/backgrounds/restaurant-1.webp')";
    long = false;
  } 

  useEffect(() => {
    getToken();
  }, []);

  return (
    <div
      style={{ backgroundImage: backgroundImage, backgroundSize: mobile ? 'cover' : 'contain', backgroundRepeat: 'no-repeat' }}
      className="inset-0 h-full w-full" //min-[1999px]:bg-cover
    >
      <Nav colored={false} switchTab={switchTab} tab={tab} />

      <div>
        {/* Tabs switcher */}
        <div>
          <BasicTabs
            colored={false}
            matches={mobile}
            longForm={long ? true : false}
            mobile={mobile}
            switchTab={switchTab}
            tab={tab}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
