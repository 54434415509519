// a component to render a bus listing in the main page
import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from '@mui/icons-material/Instagram';
import { useTheme } from "@material-ui/core/styles";
import Secondary from "../Buttons/Secondary";
import Bold from "../Text/Bold";
import Semi from "../Text/Semi";
import ActionFooter from "./ActionFooter";
import Checkout from "./Checkout";
import { useDispatch, useSelector } from "react-redux";
import { selectCheckoutDetails, selectEntertainmentPackages, selectEntertainmentForm } from "../../slices/NavSlice";
import { NumberFormatter } from "../fx/fx";
import { Dialog, DialogActions, DialogContent, Paper } from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import Regular from "../Text/Regular";
import { Divider, Skeleton } from "@mui/material";
import Submit from "../Buttons/Submit";
import Tiketika from "../Axios/AxiosConfig";
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "60%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
    },
    margin: 15,
    padding: 15,
  },
  rootMenu: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    width: "80%",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 20,
    flexDirection: "column",
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "80%",
      transform: "translate(-53%, -50%)",
    },
    padding: 15,
  },
  details: {
    width: "60%",
    margin: 10,
    padding: 5,
    borderRadius: 5,
    backgroundColor: "#CBFFB5",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    height: 150,
    width: 200,
    paddingRight: 10,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 0,
      height: 150,
      borderRadius: 10,
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

function EntertainmentInfo({id}) {
  
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  

  const [open, setOpen] = useState(false);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [message, setMessage] = useState(false);
  const [status, setStatus] = useState(false);
  const [place, setPlace] = useState();
  const [packages, setPackages] = useState();
  // state to store total menu price for items in a particular venue
  const [totalEntertainmentPrice, setTotalEntertainmentPrice] = useState(0);
  const [itemsCount, setItemsCount] = useState([]);
  const [checkoutForm, setCheckoutForm] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  
  const handleItemClick = (item) => {
    const index = selectedItems.indexOf(item);
    calculateTotalCost()
    if (index === -1) {
      // If the item is not in selectedItems, add it
      setSelectedItems([...selectedItems, item]);
      // Additional logic if needed (e.g., seatBooked(item))
    } else {
      // If the item is already in selectedItems, remove it
      const newSelectedItems = [...selectedItems];
      newSelectedItems.splice(index, 1);
      setSelectedItems(newSelectedItems);
      // Additional logic if needed (e.g., seatUnBooked(item))
    }
    console.log(selectedItems)
  };

  const isItemSelected = (item) => selectedItems.includes(item);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenResponseModal = () => setOpenResponseModal(true);
  const handleCloseResponseModal = () => {
    setMessage(false)
    setOpenResponseModal(false);
  }

  const entertainmentForm = useSelector(selectEntertainmentForm)
  const checkoutDetails = useSelector(selectCheckoutDetails);
  const entertainmentGlobalState = useSelector(selectEntertainmentPackages);
  const centerize = { display: 'flex', alignItems: 'center', flexDirection: 'column' }

  
  async function getPlace() {
    try {
      const response = await Tiketika.get("/entertainment/?id=" + id);
      if (response.data) {
        setPackages(response.data.packages)
        console.log(response.data.packages)
        setPlace(response.data.place)
      }
    } catch (error) {
      console.log("error asxsa", error);
    }
  }

  // Trigger save and USSD push for payment
  async function submitDetailsProcessPayment() {
    const { name, phone, email, payPhone } = checkoutDetails
    const { date } = entertainmentForm
    var items = [
      {
        "entertainment": {
            "entertainmentID": id,
            "items": selectedItems
        }
      }
    ]
    let amount = totalEntertainmentPrice
    
    if(payPhone){
      handleOpenResponseModal()
      try {
        const response = await Tiketika.post('/entertainment/', { name, phone, email, date, payPhone, items, amount, headers:  { "Access-Control-Allow-Origin": "*" } });
        if (response.data) {
          const { message, status } = response.data
          if(message && status){
            setMessage(message)
            setStatus(status)
          }
        }
      }
      catch (error) {
        console.log("Error", error);
      }
    }
    else{
      handleOpenResponseModal()
      setMessage('Please enter the phone number for payment')
    }
  }

  // function to send checkout details and form details to api
  const CompleteCheckout = () => {    
    submitDetailsProcessPayment()
  }

  useEffect(() => {
    getPlace()
  },[id])

  // useEffect total price for all items in itemsCount
  useEffect(() => {
    calculateTotalCost();
  }, [selectedItems]);

  const calculateTotalCost = () => {
    let total = 0;
    if(packages && packages.length){
      packages.map((item, i) => {
        let allItems = parseStringToArray(item.items)
        let selected = selectedItems.filter(item => allItems.includes(item));
        if(selected && selected.length){
          total += selected.length * parseInt(item.price)
        }
      })
      setTotalEntertainmentPrice(total);
    }
  }

  const CartCount = ({ item, entertainmentID }) => {
    var count = 0;
    itemsCount.map((thing) => {
      if (thing.package.packageID === item.packageID && thing.package.entertainmentID === entertainmentID) {
        count++;
        return count;
      }
    });

    return <Semi text={count} />;
  };

  // delete item from itemsCount array
  const deleteItem = (item, entertainmentID) => {
    const newItemsCount = itemsCount.filter((thing) => {
      return thing.package.packageID == item.packageID && thing.entertainmentID == entertainmentID;
    });

    const index = itemsCount.indexOf(newItemsCount[0]);
    itemsCount.splice(index, 1);
    setItemsCount([...itemsCount]);
  };

  const parseStringToArray = (inputString) => {
    // Extract the content within the square brackets
    const arrayContent = inputString.slice(1, -1);
    
    // Split the content by commas and add double quotes
    const resultArray = arrayContent.split(',').map(item => `${item.trim()}`);
    return resultArray;
  }


  return (
    <>
      <div
        style={{
          // ...centerize,
          // justifyContent: "space-between",
          marginBottom: 25,
          // paddingTop: '2rem',
        }}
      >

        
        {/* Response Dialog */}
        <Dialog
          open={openResponseModal}
          onClose={handleCloseResponseModal}
          aria-labelledby={'Booking Response'}
          aria-describedby={'Booking Response'}
          maxWidth="sm"
        >
          <DialogContent>
            <Grid container spacing={1}>
              {message !== false ? 
              <Semi text={message} fontSize={'1.2rem'} color={status === '00' ? "#333" : "#333"} />
              : 
              <CircularProgress />              
              }
            </Grid>
            <DialogActions>
              {message !== false &&
                <Secondary text="Close" action={() => { handleCloseResponseModal() }} />
              }
            </DialogActions>
          </DialogContent>
        </Dialog>
    
        {place ? 
        <Grid container spacing={2} className="p-10">
          <Grid item xs={12} sm={7}>
            {/* Description and details */}
            <Paper elevation={4} style={{ padding: '10px 20px', textAlign: 'left' }}>
              <div className="mb-1 pb-2 pt-1">
                <Bold text={place.entertainment} fontSize={'1.2rem'} color={"#2BA147"} />
                <Divider className="pt-1 mb-1" />
              </div>
              
              <Regular
                text={lugha.lugha === 'swahili' ? parse(place.maelezo) : parse(place.description)}
                fontSize={'1rem'}
                color={"#333"}
                align={'left'}
              />
              <div className="bottom mt-4">
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <Bold
                      text={'Follow'}
                      fontSize={'0.8rem'}
                      color={"#333"}
                      align={'left'}
                    />
                    <br />

                    <a href={place.instagram && place.instagram} target="_blank" rel="noopener noreferrer">
                      <InstagramIcon />
                    </a>
                  </Grid>

                  {/* Seatmap */}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby={lugha.seatMap}
                    aria-describedby={lugha.seatMap}
                    maxWidth="md"
                    fullWidth
                  >
                    <DialogContent>
                      {/* List the package items grouped by type */}
                      <Grid container spacing={1}>
                        <img src={`https://tiketika.co.tz/img/entertainment/${place.entertainment.replace(/[^a-zA-Z0-9 ]/g, '').split(' ').join('-').toLocaleLowerCase()}/${place.entertainment.replace(/[^a-zA-Z0-9 ]/g, '').split(' ').join('-').toLocaleLowerCase()}-seatmap.png`} alt={lugha.seatMap} />
                      </Grid>
                      <DialogActions>
                        <Secondary
                          text="Close"
                          action={() => { handleClose(); }}
                        />
                      </DialogActions>
                    </DialogContent>
                  </Dialog>


                  {/* Seatmap Button */}
                  <Grid item xs={12} sm={5}>                    
                    <Submit
                      text={lugha.view +' '+ lugha.seatMap}
                      action={() => handleOpen()}
                      inverse
                      size={'small'}
                    />
                  </Grid>
                  
                  {/* Blank */}
                  <Grid item xs={12} sm={4} />

                </Grid>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={5}>
            {/* List of Tables available for reservation */}
            <Paper elevation={4} style={{ padding: '20px' }}>
              {packages &&
                <>
                  {packages.filter(pack => parseInt(pack.packageTypeID) === 1).map((item, i) => {
                    var entertainmentID = item.entertainmentID; //I know, I know. But if somehow we're managing multiple location offerings in one info page, this doesn't consume significant resources
                    var price = item.price;
                    var description = item.description;
                    var type = item.packageType;
                    if(item.items){
                      let confirmArray = parseStringToArray(item.items)
                      if(confirmArray && confirmArray.length){
                        var items = confirmArray
                      }
                    }
                    if(lugha.lugha === 'swahili'){
                      description = item.maelezo;
                      type = item.ainaYaBurudani
                    }

                    return (
                      <>
                        {i === 0 ? 
                          <div className="mb-1 pb-2">
                            <Bold color={'#2BA147'} fontSize={'1.2rem'} text={type} />
                            <Divider />
                          </div>
                        :
                        ''
                        }
                        

                        <Grid container>
                          <Grid item xs={6} md={6}>
                            <div>
                              <Bold text={item.package} fontSize={'1.4rem'} /><br />
                              {/* <Semi text={lugha.price +': Tsh. '+ NumberFormatter(price)} /> */}
                            </div>
                          </Grid>

                          <Grid item xs={6} md={6}>
                            <Bold text={'Tsh. '+ NumberFormatter(price)} />
                          </Grid>
                          
                          <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
                              <Regular text={description} color={'#000'} />
                          </Grid>
                          
                          <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
                            <div>
                              {items.map((item, index) => (
                                <div
                                  key={index}
                                  className={`inline-block rounded border border-gray-400 bg-gray-400 mx-3 my-2 px-3 py-1.5 sm:px-2 sm:py-1 xl:px-4 xl:py-2 text-sm font-medium text-white hover:bg-transparent hover:text-gray-400 focus:outline-none focus:ring active:text-indigo-500 shadow-gray-300 shadow-lg hover:cursor-pointer ${
                                    isItemSelected(item) ? "bg-green-500 border-green-400" : ""
                                  }`}
                                  onClick={() => handleItemClick(item)}
                                >
                                  <p>{item}</p>
                                </div>
                              ))}
                            </div>
                          </Grid>

                        <div style={styles.separator}></div>
                        </Grid>
                      </>
                    );
                  })}
                  </> 
              }
              
            </Paper>
          </Grid>
        </Grid>
        :
        <Grid container spacing={2} className="p-10">
          <Grid item xs={12} sm={7}>
            <Paper elevation={4} style={{ padding: '0 20px' }}>
              <Skeleton width={200} height={50} animation={'wave'} />
              <Skeleton width={'auto'} height={300} animation={'wave'} />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Paper elevation={4} style={{ padding: '10px 40px' }}>
              <Skeleton width={'auto'} height={80} animation={'wave'} />
              <Skeleton width={'auto'} height={80} animation={'wave'} />
              <Skeleton width={'auto'} height={80} animation={'wave'} />
            </Paper>
          </Grid>
        </Grid>
        }
      </div>

      {selectedItems.length > 0 && (
        <ActionFooter
          text={lugha.checkout}
          clickAction={() => setCheckoutForm(true)}
        />
      )}

      {checkoutForm && (
        <Checkout
          total={totalEntertainmentPrice}
          text={lugha.payNow}
          type='entertainment'
          currency='Tsh'
          closeAction={() => {
            // dispatch(setCheckoutDetails([]));
            setCheckoutForm(false);
          }}
          items={selectedItems}
          details={[
            {
              type: "text",
              placeholder: "Full Name",
              name: "fullname",
            },
            {
              type: "phone",
              placeholder: "Phone Number",
              name: "phone",
            },
            {
              type: "email",
              placeholder: "Email",
              name: "email",
            },
          ]}
          action={(e) => {
            e.preventDefault();
            CompleteCheckout();
          }}
        />
      )}
    </>
  );
}

export default EntertainmentInfo;

const styles = {
  card: {
    width: 300,
    margin: 80,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    display: "flex",
    justifyContent: "center",
  },
  eventDetails: {
    textAlign: "left",
    alignItems: "left",
    flex: 2,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#e0e0e0",
    margin: 10,
  },
  priceDetails: {
    padding: 20,
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    width: "20%",
    borderRadius: 30,
  },

  anem: {
    display: "flex",
    alignItems: "center",
    margin: 5,
  },

  price: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#2BA147",
  },
};
