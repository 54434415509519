// a component to render a bus listing in the main page
import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from '@mui/icons-material/Instagram';
import { useTheme } from "@material-ui/core/styles";
import Primary from "../Buttons/Primary";
import Secondary from "../Buttons/Secondary";
import Bold from "../Text/Bold";
import Semi from "../Text/Semi";
import Regular from "../Text/Regular";
import CircularProgress from '@mui/material/CircularProgress';
import ActionFooter from "./ActionFooter";
import Checkout from "./Checkout";

import { useDispatch, useSelector } from "react-redux";
import {
  selectCheckoutDetails,
  selectEventPackages,
} from "../../slices/NavSlice";
import { NumberFormatter } from "../fx/fx";
import { Dialog, DialogActions, DialogContent, Paper } from "@material-ui/core";
import { Divider, Skeleton } from "@mui/material";
import Submit from "../Buttons/Submit";
import Tiketika from "../Axios/AxiosConfig";
import parse from 'html-react-parser';


function MatchInfo({id}) {
  
  //declare lugha
  const lugha = JSON.parse(window.sessionStorage.getItem("lugha"));  
  
  const [open, setOpen] = useState(false);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [message, setMessage] = useState(false);
  const [status, setStatus] = useState(false);
  const [match, setMatch] = useState();
  const [tiers, setTiers] = useState();
  
  // state to store total menu price for items in a particular venue
  const [totalMatchPrice, setTotalMatchPrice] = useState(0);
  const [itemsCount, setItemsCount] = useState([]);
  const [checkoutForm, setCheckoutForm] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenResponseModal = () => setOpenResponseModal(true);
  const handleCloseResponseModal = () => {
    setMessage(false);
    setOpenResponseModal(false);
  }

  const checkoutDetails = useSelector(selectCheckoutDetails);

  async function getTukio() {
    try {
      const response = await Tiketika.get("/matches/?id=" + id);
      if (response.data) {
        setTiers(response.data.match.tiers)
        setMatch(response.data.match)
      }
    } catch (error) {
      console.log("error asxsa", error);
    }
  }

  // Trigger save and USSD push for payment
  async function submitDetailsProcessPayment() {
    const { name, phone, email, payPhone } = checkoutDetails
    
    if(itemsCount){
      let amount = totalMatchPrice
      let matchID = match.matchID

      let items = itemsCount.map(item => ({
        matchID: matchID,
        tier: item.tier.tier,
        quantity: item.quantity
      }));

      if(payPhone){
        handleOpenResponseModal()
        
        try {
          const response = await Tiketika.post('/matches/', { name, phone, email, payPhone, amount, items, headers:  { "Access-Control-Allow-Origin": "*" } });
          if (response.data) {
            console.log(response);
            console.log(response.data);
            const { message, status } = response.data
            if(message && status){
              setMessage(message)
              setStatus(status)
            }
          }
        }
        catch (error) {
          console.log("Error", error);
        }
      }
      else{
        setMessage('Please enter the phone number for payment')
        handleOpenResponseModal()
      }
    }
    else{
      setMessage('Please select tickets')
      handleOpenResponseModal()
    }
  }

  
  // function to send checkout details and form details to api
  const CompleteCheckout = () => {    
    //Trigger payment via Mobile money GW
    submitDetailsProcessPayment()
  }
  
  useEffect(() => {
    getTukio()
  },[id])

  // useEffect total price for all items in itemsCount
  useEffect(() => {
    let total = 0;
    itemsCount.forEach((item) => {
      total += parseInt(item.tier.price) * parseInt(item.quantity);
    });
    setTotalMatchPrice(total);
  }, [itemsCount]);


  const CartCount = ({ item }) => {
    const foundItem = itemsCount.find((thing) => thing.tier.tier === item.tier);
    const count = foundItem ? foundItem.quantity : 0;

    return <Semi text={count} />;
  };

  const addItem = (item) => {
    setItemsCount((prevItems) => {
      const existingItem = prevItems.find((thing) => thing.tier.tier === item.tier);
      
      if (existingItem) {
        return prevItems.map((thing) =>
          thing.tier.tier === item.tier ? { ...thing, quantity: thing.quantity + 1 } : thing
        );
      } 
      else {
        return [...prevItems, { tier: item, quantity: 1 }];
      }
    });
  };

  
  const deleteItem = (item) => {
    setItemsCount((prevItems) => {
      const existingItem = prevItems.find(
        (thing) => thing.tier.tier === item.tier
      );
  
      if (existingItem) {
        console.log('existing >>', existingItem)
        if (existingItem.quantity === 1) {
          return prevItems.filter((thing) => thing.tier.tier !== item.tier);
        } 
        else {
          return prevItems.map((thing) => thing.tier.tier === item.tier
            ? { ...thing, quantity: thing.quantity - 1 } : thing
          );
        }
      }
      console.log('prev >>', prevItems)
      return prevItems;
    });
  };
  

  return (
    <>
      <div
        style={{
          // ...centerize,
          // justifyContent: "space-between",
          marginBottom: 25,
          // paddingTop: '2rem',
        }}
      >
    
        {/* Response Dialog */}
        <Dialog
          open={openResponseModal}
          onClose={handleCloseResponseModal}
          aria-labelledby={'Booking Response'}
          aria-describedby={'Booking Response'}
          maxWidth="sm"
        >
          <DialogContent>
            <Grid container spacing={1}>
              {message !== false ? 
              <Semi text={message} fontSize={'1.2rem'} color={status === '00' ? "#333" : "#333"} />
              : 
              <CircularProgress />              
              }
              
            </Grid>
            <DialogActions>
              {message !== false &&
                <Secondary text="Close" action={() => { handleCloseResponseModal() }} />
              }
            </DialogActions>
          </DialogContent>
        </Dialog>

        {match ? 
        <Grid container spacing={2} className="p-10">
          <Grid item xs={12} sm={7}>
            {/* Description and details */}
            <Paper elevation={4} style={{ padding: '10px 20px', textAlign: 'left' }}>
              <div className="mb-1 pb-2 pt-1">
                <Bold text={match.homeTeam + ' vs. ' + match.awayTeam} fontSize={'1.2rem'} color={"#2BA147"} />
                <Divider className="pt-1 mb-1" />
              </div>
              
              <Regular
                text={lugha.matchInfo}
                fontSize={'1rem'}
                color={"#333"}
                align={'left'}
              />
              <div className="bottom mt-4">
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <Bold
                      text={'Follow'}
                      fontSize={'0.8rem'}
                      color={"#333"}
                      align={'left'}
                    />
                    <br />

                    <a href={match.homeTeamInstagram && match.homeTeamInstagram} target="_blank" rel="noopener noreferrer">
                      Home Team<InstagramIcon />
                    </a>
                    <br />

                    <a href={match.awayTeamInstagram && match.awayTeamInstagram} target="_blank" rel="noopener noreferrer">
                      Away Team<InstagramIcon />
                    </a>
                  </Grid>

                  {/* Seatmap */}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby={lugha.seatMap}
                    aria-describedby={lugha.seatMap}
                    maxWidth="md"
                    fullWidth
                  >
                    <DialogContent>
                      {/* List the package items grouped by type */}
                      <Grid container spacing={1}>
                        <img src={match.seatmap} alt={lugha.seatMap} />
                      </Grid>
                      <DialogActions>
                        <Secondary
                          text="Close"
                          action={() => { handleClose(); }}
                        />
                      </DialogActions>
                    </DialogContent>
                  </Dialog>

                  {/* Seatmap Button */}
                  <Grid item xs={12} sm={5}>                    
                    <Submit
                      text={lugha.view +' '+ lugha.seats}
                      action={() => handleOpen()}
                      inverse
                      size={'small'}
                    />
                  </Grid>
                  
                  {/* Blank */}
                  <Grid item xs={12} sm={4} />

                </Grid>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={5}>
            {/* List of Tables available for reservation */}
            <Paper elevation={4} style={{ padding: '20px' }}>
              {tiers &&
                <>
                
                  {tiers.map((item, i) => {
                    const { tier, price } = item

                    return (
                      <>
                        {i === 0 ? 
                          <div className="mb-1 pb-2">
                            <Bold color={'#2BA147'} fontSize={'1.2rem'} text={match.competition} />
                            <Divider />
                          </div>
                        :
                        ''
                        }
                        

                        <Grid container>
                          <Grid item xs={6} md={6}>
                            <div>
                              <Bold text={tier} fontSize={'1.4rem'} /><br />
                              <Semi text={'Tsh. '+ NumberFormatter(price)} />
                              {/* <Semi text={lugha.price +': Tsh. '+ NumberFormatter(price)} /> */}
                            </div>
                          </Grid>

                          <Grid item xs={6} md={6}>
                            {/* Cart Counter */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <Primary
                                text={"-"}
                                action={() => {
                                  deleteItem(item);
                                }}
                              />

                              <CartCount item={item} tier={tier} />

                              <Primary
                                text={"+"}
                                action={() => {
                                  addItem(item);
                                }}
                              />
                            </div>
                          </Grid>
                          
                          {/* <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
                              <Regular text={description} color={'#000'} />
                          </Grid> */}

                        <div style={styles.separator}></div>
                        </Grid>
                      </>
                    );
                  })}
                  </> 
              }
              
            </Paper>
          </Grid>
        </Grid>
        :
        <Grid container spacing={2} className="p-10">
          <Grid item xs={12} sm={7}>
            <Paper elevation={4} style={{ padding: '0 20px' }}>
              <Skeleton width={200} height={50} animation={'wave'} />
              <Skeleton width={'auto'} height={300} animation={'wave'} />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Paper elevation={4} style={{ padding: '10px 40px' }}>
              <Skeleton width={'auto'} height={80} animation={'wave'} />
              <Skeleton width={'auto'} height={80} animation={'wave'} />
              <Skeleton width={'auto'} height={80} animation={'wave'} />
            </Paper>
          </Grid>
        </Grid>
        }
      </div>

      {itemsCount.length > 0 && (
        <ActionFooter
          text={lugha.checkout}
          clickAction={() => setCheckoutForm(true)}
        />
      )}

      {checkoutForm && (
        <Checkout
          total={totalMatchPrice}
          text={lugha.payNow}
          type='match'
          currency='Tsh'
          closeAction={() => {
            // dispatch(setCheckoutDetails([]));
            setCheckoutForm(false);
          }}
          items={itemsCount}
          details={[
            {
              type: "text",
              placeholder: "Full Name",
              name: "fullname",
            },
            {
              type: "phone",
              placeholder: "Phone Number",
              name: "phone",
            },
            {
              type: "email",
              placeholder: "Email",
              name: "email",
            },
          ]}
          action={(e) => {
            e.preventDefault();
            CompleteCheckout();
          }}
        />
      )}
    </>
  );
}

export default MatchInfo;

const styles = {
  card: {
    width: 300,
    margin: 80,
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
    display: "flex",
    justifyContent: "center",
  },
  eventDetails: {
    textAlign: "left",
    alignItems: "left",
    flex: 2,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#e0e0e0",
    margin: 10,
  },
  priceDetails: {
    padding: 20,
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    width: "20%",
    borderRadius: 30,
  },

  anem: {
    display: "flex",
    alignItems: "center",
    margin: 5,
  },

  price: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#2BA147",
  },
};
