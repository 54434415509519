import React, { useState, useEffect } from "react";
import Nav from "../components/Main/Nav";
import { useParams } from "react-router-dom";
import MatchInfo from "../components/Main/MatchInfo";
import FooterNav from "../components/Main/FooterNav";

function MatchView() {

  const { matchID } = useParams()
  return (
    <div style={styles.container}>
      {/* Navigation */}
      <Nav colored={true} />
      <MatchInfo id={matchID} />  
      <FooterNav />
    </div>
  );
}

export default MatchView;

const styles = {
  container: {
    // backgroundColor: 'red',
  },

  tabs: {
    position: "absolute",
    top: 85,
  },
};
