import React, { useEffect, useState } from "react";
import Bold from "./../Text/Bold";
import Secondary from "./../Buttons/Secondary";
import Primary from "./../Buttons/Primary";
import Regular from "./../Text/Regular";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import BInput from "../Inputs/BInput";
import SignUp from "./SignUp";
import LogIn from "./LogIn";
import Profile from "./Profile";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { swahili, english } from "../Language/Language";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import LogOutIcon from "@mui/icons-material/Logout";
import ProfileIcon from "@mui/icons-material/AccountBox";

import { makeStyles } from "@material-ui/core/styles";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import TicketStubIcon from "@mui/icons-material/ConfirmationNumber";
import AxiosInstance from "../Axios/AxiosConfig";
import { Orodha } from "../fx/list";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  p: 4,
  // boxShadow: 24,
  // borderRadius: 15,
  background:
    "url('https://tiketika.co.tz/img/backgrounds/tiketika-medium-web-banner.png')",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
};

const useStyles = makeStyles((theme) => ({
  basic: {
    padding: 20,
    borderRadius: 15,
    width: "85%",
    border: "none",
    outline: "none",
    margin: 5,
    fontFamily: "Euclid-Bold",
    boxShadow: "-1px 2px 2px rgba(0, 0, 0, 0.57)",
    fontSize: 20,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "55vw",
    },
  },

  box: {
    width: 400,
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
}));

function Nav({ colored, tab, switchTab }) {
  //Set default language

  if (window.sessionStorage.getItem("language") === undefined) {
    //Is it blank? Set to Swahili by default if user IP location is outside Tanzania
    //I don't want another 3rd party service for this, I'll just use Timezone for now

    const userTimezoneOffset = new Date().getTimezoneOffset() / 60;
    const isGMTPlus3 = userTimezoneOffset === -3;
    if(isGMTPlus3){
      window.sessionStorage.setItem("lugha", JSON.stringify(swahili));
      window.sessionStorage.setItem("language", "SW");
    }
    else{
      window.sessionStorage.setItem("lugha", JSON.stringify(english));
      window.sessionStorage.setItem("language", "EN");
    }

  } 
  else {
    if (window.sessionStorage.getItem("language") === "EN") {
      //Is it english?
      window.sessionStorage.setItem("lugha", JSON.stringify(english));
    } else {
      //It's not English
      window.sessionStorage.setItem("lugha", JSON.stringify(swahili));
    }
  }

  //Initiate list items, refresh on page reload
  // useEffect(() => {
  //   Orodha();
  // }, [])
  
  // Once regions are loaded, refresh
  window.addEventListener('regionsLoaded', function() {
    window.location.reload();
  });

  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openLogin, setOpenLogin] = useState(false);
  const [language, setLanguage] = useState("SW");
  const [lugha, setLugha] = useState(
    JSON.parse(window.sessionStorage.getItem("lugha"))
  );
  const [openSignUp, setOpenSignUp] = useState(false);
  const [logout, setLogout] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openDetails = Boolean(anchorEl);

  const handleOpenLogin = () => setOpenLogin(true);
  const handleCloseLogin = () => setOpenLogin(false);

  const handleOpenSignUp = () => setOpenSignUp(true);
  const handleCloseSignUp = () => setOpenSignUp(false);

  const handleOpenProfile = () => setOpenProfile(true);
  const handleCloseProfile = () => setOpenProfile(false);
  

  const handleLanguage = (e) => {
    setLanguage(e.target.value);
    translation(e.target.value);
  };

  const translation = (language) => {
    if (language === "EN") {
      window.sessionStorage.setItem("lugha", JSON.stringify(english));
      window.sessionStorage.setItem("language", "EN");
    } else {
      window.sessionStorage.setItem("lugha", JSON.stringify(swahili));
      window.sessionStorage.setItem("language", "SW");
    }
    document.location.reload();
  };

  const handleClickDetails = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDetails = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    if (logout && sessionStorage.getItem("name")) {
      sessionStorage.clear();
      document.location.reload();
    }
  };
  
  useEffect(() => {
    logOut();
  }, [logout]);

  return (
    <>
      <div
        style={{
          top: 0,
          backgroundColor: colored ? "#13601F" : "#FFF",
          width: "100vw",
          paddingTop: 10,
          paddingBottom: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          zIndex: 5,
        }}
      >
        <Link to="/" style={{ textDecoration: "none", color: "#13601F" }}>
          {mobile ? (
            <HomeIcon /> // sx={{ filter: "drop-shadow(1px 1px 1px #333333)" }}
          ) : 
          <>
            {
            colored ? 
              <img src='https://tiketika.co.tz/img/assets/tiketika-logo-white.png' alt='Tiketika' style={{ width: 65, height: 65 }} />
            :
            <img src='https://tiketika.co.tz/img/assets/tiketika-logo.png' alt='Tiketika' style={{ width: 65, height: 65 }} />
            }
          </>
           }
        </Link>

        {!mobile && 
          <div>
              
            <Link to={'/'}>
              <Bold
                text={lugha.navBus}
                color={colored ? "#FFF" : "#2BA147"}
                padding={'0 20px'}
                fontSize={16}
                onclick={() => switchTab(0)}
              />
            </Link>
                 
            <Link to={'/'}>
              <Bold
                text={lugha.navFlight}
                color={colored ? "#FFF" : "#2BA147"}
                padding={'0 20px'}
                fontSize={16}
                onclick={() => switchTab(1)}
              />
            </Link>
              
            <Link to={'/'}>
              <Bold
                text={lugha.navEntertainment}
                color={colored ? "#FFF" : "#2BA147"}
                padding={'0 20px'}
                fontSize={16}
                onclick={() => switchTab(2)}
              />
            </Link>
              
            <Link to={'/'}>
              <Bold
                text={lugha.navTour}
                color={colored ? "#FFF" : "#2BA147"}
                padding={'0 20px'}
                fontSize={16}
                onclick={() => switchTab(3)}
              />
            </Link>
              
            <Link to={'/'}>
              <Bold
                text={lugha.navHotel}
                color={colored ? "#FFF" : "#2BA147"}
                padding={'0 20px'}
                fontSize={16}
                onclick={() => switchTab(4)}
              />
            </Link>
              
            <Link to={'/'}>
              <Bold
                text={lugha.navEvent}
                color={colored ? "#FFF" : "#2BA147"}
                padding={'0 20px'}
                fontSize={16}
                onclick={() => switchTab(5)}
              />
            </Link>

          </div>
        }
        <div>
          {sessionStorage.getItem("name") ? (
            <Tooltip title="View your tickets" sx={{ cursor: "pointer" }}>
              <Button
                onClick={handleClickDetails}
                aria-controls={openDetails ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDetails ? "true" : undefined}
                style={{ color: colored ? "#FFF" : "#2BA147" }}
              >
                <Bold
                  text={sessionStorage.getItem("name")}
                  color={colored ? "#FFF" : "#2BA147"}
                  fontSize={16}
                  // textShadow={"2px 2px 2px #333333"}
                />
                {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
            </Tooltip>
          ) : (
            <>
              <Button onClick={handleOpenLogin}>
                <Secondary text={lugha.logInButton} />
              </Button>
              <Button onClick={handleOpenSignUp}>
                <Primary text={lugha.registerButton} />
              </Button>
            </>
          )}

          <Select
            sx={{
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              ".MuiSvgIcon-root": { fill: colored ? "#FFF" : "#2BA147" },
            }}
            //value={ language }
                        style={{ color: colored ? "#FFF" : "#2BA147" }}
            value={
              window.sessionStorage.getItem("language")
                ? window.sessionStorage.getItem("language")
                : language
            }
            onChange={handleLanguage}
            inputProps={{ "aria-label": "Language" }}
          >
            <MenuItem value={"SW"}>SW</MenuItem>
            <MenuItem value={"EN"}>EN</MenuItem>
          </Select>
        </div>
      </div>

      {/* Details */}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openDetails}
        onClose={handleCloseDetails}
        onClick={handleCloseDetails}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              // bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Link to="/tickets">
            <ListItemIcon>
              <TicketStubIcon fontSize="small" />
            </ListItemIcon>
            {lugha.myTickets}
          </Link>
        </MenuItem>

        <MenuItem onClick={() => handleOpenProfile()}>
          <ListItemIcon>
            <ProfileIcon fontSize="small" />
          </ListItemIcon>
          {lugha.profile}
        </MenuItem>

        <MenuItem onClick={() => setLogout(true)}>
          <ListItemIcon>
            <LogOutIcon fontSize="small" />
          </ListItemIcon>
          {lugha.logOut}
        </MenuItem>
      </Menu>

      {/* Login */}
      <div id="basicModal">
        <Modal
          open={openLogin}
          onClose={handleCloseLogin}
          aria-labelledby="Log in for returning users"
          aria-describedby="Log in before you book to stand a change to win rewards"
        >
          <Box sx={style} className={classes.box}>
            <IconButton
              aria-label="close"
              onClick={handleCloseLogin}
              sx={{
                position: "absolute",
                right: "3rem",
                top: "3rem",
                color: "#fff",
              }}
            >
              <CancelIcon />
            </IconButton>
            {/*Login Component*/}
            <LogIn handleCloseLogin={handleCloseLogin} />
          </Box>
        </Modal>
      </div>

      {/* Sign Up */}
      <div id="SignUpModal">
        <Modal
          open={openSignUp}
          onClose={handleCloseSignUp}
          aria-labelledby="modal-modal-title"
          aria-describedby="Register an account with us and collect points and stand a change to win rewards"
        >
          <Box sx={style} className={classes.box}>
            <IconButton
              aria-label="close"
              onClick={handleCloseSignUp}
              sx={{
                position: "absolute",
                right: "2rem",
                top: "5rem",
                color: "#fff",
              }}
            >
              <CancelIcon />
            </IconButton>

            {/*Sign Up Component*/}
            <SignUp />
          </Box>
        </Modal>
      </div>

      {/* Profile */}
      <div id="profileModal">
        <Modal
          open={openProfile}
          onClose={handleCloseProfile}
          aria-labelledby="Log in for returning users"
          aria-describedby="Log in before you book to stand a change to win rewards"
        >
          <Box sx={style} className={classes.box}>
            <IconButton
              aria-label="close"
              onClick={handleCloseProfile}
              sx={{
                position: "absolute",
                right: "3rem",
                top: "4rem",
                color: "#fff",
              }}
            >
              <CancelIcon />
            </IconButton>
            {/*Login Component*/}
            <Profile handleCloseProfile={handleCloseProfile} />
          </Box>
        </Modal>
      </div>

    </>
  );
}

export default Nav;

// const styles = {
//   outer: {
//     position: "absolute",
//     top: 0,
//     backgroundColor: "#64d375",
//     width: "100vw",
//     padding: 20,
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-around",
//     alignItems: "center",
//     zIndex: 5,
//   },
// };
