// a component to render a bus listing in the main page
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Primary from '../Buttons/Primary';
import Secondary from '../Buttons/Secondary';
import Bold from '../Text/Bold';
import Semi from '../Text/Semi';
import ActionFooter from './ActionFooter';
import Checkout from './Checkout';
import Modal from '@mui/material/Modal';

import { useDispatch, useSelector } from 'react-redux';
import {
    selectAvailableRests,
    selectChosenBus,
    setChosenBus,
    selectBusForm,
    selectRestForm,
    setHomeBg,
    selectCheckoutDetails,
    setCheckoutDetails
} from '../../slices/NavSlice';


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },

    root: {
        display: 'flex',
        width: '60%',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 20,
        boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '80%',
        },
        margin: 15,
        padding: 15
    },

    rootMenu: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        width: '30%',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 20,
        flexDirection: 'column',
        boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '80%',
            transform: 'translate(-53%, -50%)',
        },
        padding: 15
    },

    details: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            padding: 10,
        },
    },

    content: {
        flex: '1 0 auto',
    },

    cover: {
        height: 150,
        width: 200,
        paddingRight: 10,
        borderRadius: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
            height: 150,
            borderRadius: 10,
        },
    },

    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },

    playIcon: {
        height: 38,
        width: 38,
    },
}));


function RestListing({ arrivalDate }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const availableRests = useSelector(selectAvailableRests);

    const [showMenu, setShowMenu] = useState();

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const restGlobalState = useSelector(selectRestForm);
    const checkoutDetails = useSelector(selectCheckoutDetails);

    const [location, setLocation] = useState(restGlobalState ? restGlobalState.location : '');
    const [date, setDate] = useState(restGlobalState ? restGlobalState.date : '');
    const [time, setTime] = useState(restGlobalState ? restGlobalState.time : '');

    // state to store total menu price for items in a particular restaurant
    const [totalMenuPrice, setTotalMenuPrice] = useState(0);
    const [itemsCount, setItemsCount] = useState([]);

    // checkout toggle
    const [checkout, setCheckout] = useState(false);

    // useEffect total price for all items in itemsCount
    useEffect(() => {
        let total = 0;
        itemsCount.forEach(item => {
            total += item.price * item.quantity;
        })
        setTotalMenuPrice(total);
        console.log('totalMenuPrice', totalMenuPrice);
        console.log('itemsCount', itemsCount)
    }, [itemsCount])

    const oneRest = [
        {
            "restaurantID": 1,
            "restaurant": "Kilimanjaro Motorway Fast Food",
            "path": "kilimanjaro-motorway-fast-food/kilimanjaro-motorway-fast-food-1.png",
            "location": "Korogwe",
            "menu": [
                {
                    "menuID": 1,
                    "name": "Kilimanjaro Burger",
                    "price": 10000,
                    "path": "kilimanjaro-motorway-fast-food/kilimanjaro-burger.png"
                },
                {
                    "menuID": 2,
                    "name": "Kilimanjaro Pizza",
                    "price": 15000,
                    "path": "kilimanjaro-motorway-fast-food/kilimanjaro-pizza.png"
                },
                {
                    "menuID": 3,
                    "name": "Kilimanjaro Fries",
                    "price": 5000,
                    "path": "kilimanjaro-motorway-fast-food/kilimanjaro-fries.png"
                },
            ]
        },
        {
            "restaurantID": 2,
            "restaurant": "Liverpool Restaurant",
            "path": "liverpool/liverpool-1.png",
            "location": "Korogwe",
            "menu": [
                {
                    "menuID": 1,
                    "name": "Liverpool Burger",
                    "price": 10000,
                    "path": "kilimanjaro-motorway-fast-food/kilimanjaro-burger.png"
                },
                {
                    "menuID": 2,
                    "name": "Liverpool Pizza",
                    "price": 15000,
                    "path": "kilimanjaro-motorway-fast-food/kilimanjaro-pizza.png"
                },
                {
                    "menuID": 3,
                    "name": "Liverpool Fries",
                    "price": 5000,
                    "path": "kilimanjaro-motorway-fast-food/kilimanjaro-fries.png"
                },
            ]
        },
        {
            "restaurantID": 3,
            "restaurant": "Rock Hill Restaurant",
            "path": "rock-hill/rock-hill-1.png",
            "location": "Korogwe",
            "menu": [
                {
                    "menuID": 1,
                    "name": "Rock Hill Burger",
                    "price": 10000,
                    "path": "kilimanjaro-motorway-fast-food/kilimanjaro-burger.png"
                },
                {
                    "menuID": 2,
                    "name": "Rock Hill Pizza",
                    "price": 15000,
                    "path": "kilimanjaro-motorway-fast-food/kilimanjaro-pizza.png"
                },
                {
                    "menuID": 3,
                    "name": "Rock Hill Fries",
                    "price": 5000,
                    "path": "kilimanjaro-motorway-fast-food/kilimanjaro-fries.png"
                },
            ]
        }
    ]

    const CartCount = ({ menu, restID }) => {
        var count = 0;
        itemsCount.map((item) => {
            if (item.menu.menuItemID === menu.menuItemID && item.restaurantID === restID) {
                count++;
                return count;
            }
        })

        return (
            <Semi text={count} />
        )

    }

    // delete item from itemsCount array
    const deleteItem = (menu, restID) => {
        const newItemsCount = itemsCount.filter((item) => {
            return item.menu.menuItemID == menu.menuItemID && item.restaurantID == restID;
        })

        const index = itemsCount.indexOf(newItemsCount[0]);
        itemsCount.splice(index, 1)
        setItemsCount([...itemsCount]);
    }

    // function to send checkout details and rest form details to api 
    const sendCheckoutDetails = (fullName, phoneNumber, email) => {
        const restDetails = restGlobalState;
        const checkOutItems = itemsCount;
        const headers = {
            'Access-Control-Allow-Origin': '*'
        };

        // send data to api
        axios.post('https://tiketika.co.tz/api/booking/?type=restaurant', {
            fullName: fullName.fullName,
            phoneNumber: phoneNumber.phoneNumber,
            email: email.email,
            locationID: restDetails.location,
            date: restDetails.date,
            time: restDetails.time,
            checkOutItems
        }, { headers })
            .then((response) => {
                console.log(response);
            }, (error) => {
                console.log(error);
            });
    }


    return (
        <>
            <div className={classes.container} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', marginBottom: 25 }}>
                {availableRests && availableRests.map((rest, id) => {
                    var rest_id = rest.restaurantID;
                    var rest_location = rest.location;
                    var rest_name = rest.restaurant;
                    var rest_date = restGlobalState.date;
                    var rest_path = rest.path;
                    var rest_menu = rest.menu;

                    return (
                        <>

                            <Card key={id} className={classes.root}>
                                <img
                                    src={'https://tiketika.co.tz/img/restaurants/' + rest_path}
                                    alt="Restaurant Image"
                                    className={classes.cover}
                                />

                                <div style={styles.restDetails}>
                                    <div>
                                        <Bold text={rest_name} />
                                        <div style={{ padding: 5, borderRadius: 5, backgroundColor: '#CBFFB5' }}>
                                            <Semi text={"Location: " + rest_location} />
                                        </div>
                                    </div>

                                    <div style={styles.separator}></div>

                                    <div><Bold text='Rating'></Bold> : <Semi text={"Classic"} /></div>
                                    <div><Bold text='Description'></Bold> : <Semi text={"Description"} /></div>
                                </div>

                                <div>
                                    <Primary
                                        text='See Menu'
                                        id={rest_id}
                                        action={() => {
                                            setShowMenu(rest_id);
                                        }}
                                    />
                                </div>
                            </Card>

                            {
                                (showMenu === rest_id) &&
                                <Modal
                                    open={true}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >

                                    <Card key={rest_id + "menu"} className={classes.rootMenu} id={rest_id + "menu"} >
                                        {/* Total menu price */}
                                        <Bold text={rest_name + ' Menu'} />
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', padding: 10, }}>
                                            <Bold text='Total Price' />
                                            <Semi text={'Tsh ' + totalMenuPrice} />
                                        </div>
                                        {/* List All the menu items */}
                                        {rest_menu.map((menu, i) => {
                                            var menu_id = menu.menuItemID;
                                            var menu_name = menu.menuItemName;
                                            var menu_price = menu.price;
                                            var menu_path = menu.path;

                                            return (

                                                <>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 40, paddingRight: 70, width: '100%' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', marginLeft: 10, }}>
                                                                <Bold text={menu_name} />
                                                                <Semi text={"Price. " + menu_price} />
                                                            </div>
                                                        </div>

                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', }}>

                                                            <Primary
                                                                text={'-'}
                                                                action={() => {
                                                                    deleteItem(menu, rest_id);
                                                                }}
                                                            />

                                                            <CartCount menu={menu} restID={rest_id} />

                                                            <Primary
                                                                text={'+'}
                                                                action={() => {
                                                                    // add item to items array

                                                                    var newItem = {
                                                                        restaurantID: rest_id,
                                                                        menu,
                                                                        price: menu_price,
                                                                        quantity: 1,
                                                                    }

                                                                    setItemsCount([...itemsCount, newItem]);

                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={styles.separator}></div>

                                                </>

                                            )
                                        })}
                                        <Secondary
                                            text='Close'
                                            action={() => {
                                                setShowMenu(0);
                                                console.log(showMenu);
                                            }}
                                        />
                                    </Card>
                                </Modal>

                            }

                        </>


                    )
                })}

                {!availableRests &&
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: 300 }}>
                        <Bold text='No Restaurants Available' fontSize={20} />
                    </div>
                }
            </div>

            {itemsCount.length > 0 && <ActionFooter text={'Checkout'} clickAction={() => setCheckout(true)} />}

            {checkout && <Checkout
                items={itemsCount}
                total={totalMenuPrice}
                type='restaurant'
                currency='Tsh'
                text='Pay now'
                closeAction={() => {
                    dispatch(setCheckoutDetails([]))
                    setCheckout(false)
                }}
                details={[
                    {
                        type: 'text',
                        placeholder: 'Full Name',
                        name: 'fullName',

                    },
                    {
                        type: 'phone',
                        placeholder: 'Phone Number',
                        name: 'phoneNumber',

                    },
                    {
                        type: 'email',
                        placeholder: 'Email',
                        name: 'email',

                    },
                ]}

                action={(e) => {
                    e.preventDefault();
                    // sendCheckoutDetails();

                    var fullName;
                    var phoneNumber;
                    var email;

                    for (let i = 0; i < checkoutDetails.length; i++) {
                        fullName = checkoutDetails[0];
                        phoneNumber = checkoutDetails[1];
                        email = checkoutDetails[2]
                    }

                    sendCheckoutDetails(fullName, phoneNumber, email)
                }}
            />}

        </>

    );
}

export default RestListing

const styles = {
    card: {
        width: 300,
        margin: 80,
        boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.07)",
        display: 'flex',
        justifyContent: 'center',
    },
    restDetails: {
        textAlign: 'left',
        alignItems: 'left',
    },
    separator: {
        width: '100%',
        height: 1,
        backgroundColor: '#e0e0e0',
        margin: 10,
    },
    priceDetails: {
        padding: 20,
        backgroundColor: '#f5f5f5',
        textAlign: 'center',
        width: '20%',
        borderRadius: 30
    },

    anem: {
        display: 'flex',
        alignItems: 'center',
        margin: 5,
    },

    price: {
        fontSize: 30,
        fontWeight: 'bold',
        color: "#2BA147",
    }

}


