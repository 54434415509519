import React, { useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import ToIcon from '@mui/icons-material/East';
import TicketIcon from "@mui/icons-material/ConfirmationNumber";
import Carousel from 'react-material-ui-carousel'
import { Button, Grid, Paper } from "@mui/material";
import Primary from "../Buttons/Primary";
import Bold from "../Text/Bold";
import Regular from "../Text/Regular";
import Light from "../Text/Light";
import { NumberFormatter } from "../fx/fx";
import { useDispatch } from "react-redux";
import Tiketika from "../Axios/AxiosConfig";
import { addBusForm, setAvailableBuses, setBookedSeat, setChosenBus } from "../../slices/NavSlice";
import moment from "moment";
import Submit from "../Buttons/Submit";

function PopularRoutesCarousel() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  var lugha = JSON.parse(sessionStorage.getItem("lugha"));

  // an array of routes
  const routes = [
    {
      background: "https://tiketika.co.tz/img/landmarks/dar-tanzanite-bridge-1.webp",
      kutoka: "MWANZA",
      kuenda: "DAR",
      origin: "20",
      destination: "2",
      price: "56000",
      width: "100%",
    },

    {
      background: "https://tiketika.co.tz/img/landmarks/mbeya-roundabout-1.webp",
      kutoka: "MWANZA",
      kuenda: "MBEYA",
      origin: "20",
      destination: "16",
      price: "32000",
      width: "100%",
    },

    {
      background: "https://tiketika.co.tz/img/landmarks/dodoma-nyerere-statue-1.webp",
      kutoka: "DAR",
      kuenda: "DODOMA",
      origin: "2",
      destination: "3",
      price: "24000",
      width: "100%",
    },

    {
      background: "https://tiketika.co.tz/img/landmarks/arusha-town-monument-2.webp",
      kutoka: "DAR",
      kuenda: "ARUSHA",
      origin: "2",
      destination: "1",
      price: "32000",
      width: "100%",
    },

    {
      background: "https://tiketika.co.tz/img/landmarks/mwanza-rocks-1.webp",
      kutoka: "DAR",
      kuenda: "KIGOMA",
      origin: "2",
      destination: "9",
      price: "32000",
      width: "100%",
    },

    {
      background: "https://tiketika.co.tz/img/landmarks/tanga-roundabout-1.webp",
      kutoka: "DAR",
      kuenda: "TANGA",
      origin: "2",
      destination: "27",
      price: "58000",
      width: "100%",
    },
  ];

  async function getBus(origin, destination, date) {
    try {
      
      const response = await Tiketika.post("/search/?type=bus", {
        origin, destination, date, 
      });

      const content = response.data;
      console.log(content)
      if (content.buses.length === 0) {
        dispatch(setAvailableBuses(null));
      } else {
        dispatch(setAvailableBuses(content.buses));
        dispatch(setChosenBus([]));
        dispatch(setBookedSeat([]));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const Item = (props) => {
    const { item } = props
    let paperStyle = {
      background: `linear-gradient(to bottom, rgba(50, 50, 50, 0), rgba(50, 50, 50, 1)), url(${item.background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '400px',
    }

    let contentStyle = {
      position: 'absolute',
      bottom: 10,
      padding: 8,
      width: mobile ? '100%':'33%',
      textAlign: 'left',
    }

    let route = (<>{item.kutoka} <ToIcon /> {item.kuenda}</>)
    return (
      <Paper elevation={2} style={paperStyle}>
          <div style={contentStyle}>
            <Grid container>
              <Grid item xs={8} sm={8}>
                <Bold text={route} fontSize={'1.2rem'} color={'#FFF'} /> 
              </Grid>
              <Grid item xs={4} sm={4} style={{ textAlign: 'right', paddingRight: '1rem' }}>
                <TicketIcon style={{ color: '#FFF', textAlign: 'right' }} />
              </Grid>
            </Grid>
            
            <br />

            <Grid container>
              <Grid item xs={7} sm={7}>
                <Light text={lugha.from} fontSize={'0.7rem'} color={'#FFF'} /> <br />
                <Regular text={'TZS ' + NumberFormatter(item.price)} fontSize={'1.2rem'} />
              </Grid>
              <Grid item xs={5} sm={5} style={{ display: 'flex' }}>
                <Submit
                  text={lugha.book.toLocaleUpperCase()}
                  action={() => {
                    getBus(item.origin, item.destination, moment().format('YYYY-MM-DD'))
                  }}
                  pathTo="/buses"
                  size={'small'}
                />
              </Grid>
            </Grid>
          </div>

      </Paper>
    )
  }
  
  let carouselContent;

  if (mobile) {
    // Render individual Paper components for each slide on small screens
    carouselContent = routes.map((item, i) => (
      <Item key={i} item={item} />
    ));
  } else {
    // Calculate the number of slides for larger screens
    const slidesPerPage = Math.ceil(routes.length / 3);

    // Render slides based on the calculated number using Grid
    carouselContent = [...Array(slidesPerPage)].map((_, index) => (
      <Grid container spacing={2} key={index}>
        {routes.slice(index * 3, (index + 1) * 3).map((item, i) => (
          <Grid item xs={4} key={i}>
            <Item item={item} />
          </Grid>
        ))}
      </Grid>
    ));
  }

  return (
    <>
      <div className="text-center text-xl py-8">
        <Bold
          fontSize={mobile ? 26 : 36}
          text={lugha.busPopular}
          color={"#333"}
        />
      </div>

      <div style={{ width: '80%', margin: '0 auto' }}>
        <Carousel autoPlay stopAutoPlayOnHover navButtonsAlwaysVisible
        animation="slide" 
          swipe interval={50000} 
          duration={500} 
          slidesPerPage={3}
          navButtonsProps={{ 
            style: {
              backgroundColor: '#2DA148',
            }
          }} 
        >
          {carouselContent}
        </Carousel>
      </div>
    </>
  );

}

export default PopularRoutesCarousel;

{
  /* <img src={routes[3].url} alt={routes[3].title} style={{ width: '100%' }} /> */
}
