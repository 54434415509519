import React, { useState, useEffect } from "react";
import BInput from "../Inputs/BInput";
import DatePicker from "../Inputs/DatePicker";
import Primary from "../Buttons/Primary";
import Submit from "./../Buttons/Submit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import LocationInput from "../Inputs/LocationInput";
import { useNavigate } from "react-router-dom";
import { Link, useHistory, useLocation } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import {
  addRestForm,
  selectRestForm,
  setHomeBg,
  setAvailableRests,
} from "../../slices/NavSlice";
import { BottomPattern, GreenLogo } from "../../assets/images";
import Tiketika from "../Axios/AxiosConfig";
import TimeInput from "../Inputs/TimeInput";
import TimePicker from "../Inputs/TimePicker";

const useStyles = makeStyles((theme) => ({
  restForm: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
    },
  },

  formHome: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    border: "2px solid red",
    // marginTop: "27%",
    marginRight: "15%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
    },
  },

  formHomeS: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    //marginTop: '92%',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: "5%",
      paddingTop: "16%",
    },
  },
}));

function RestForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const restGlobalState = useSelector(selectRestForm);

  const browserLocation = useLocation();

  const [timeFocus, setTimeFocus] = useState("text");
  const [dateFocus, setDateFocus] = useState("text");

  // Form variables
  const [location, setLocation] = useState(
    restGlobalState ? restGlobalState.location : ""
  );
  const [date, setDate] = useState(restGlobalState ? restGlobalState.date : "");
  const [time, setTime] = useState(restGlobalState ? restGlobalState.time : "");
  const [displayTime, setDisplayTime] = useState("");

  const [locationOptions, setLocationOptions] = useState(window.sessionStorage.getItem('locations') ? 
  JSON.parse(window.sessionStorage.getItem('locations')) 
  : 
  []);

  const [locationError, setLocationError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);

  const [generalError, setGeneralError] = useState(false);

  const postRestFormData = () => {(
    async () => {
      const response = await fetch(
        "https://tiketika.co.tz/api/search/?type=restaurant",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Allow-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            location,
            date,
            time,
          }),
        }
      );

      const content = await response.json();
      if (content.menus && content.menus.length !== 0) {
        console.log(content.menus);
        dispatch(setAvailableRests(content.menus));
        setHomeBg(0);
      } 
      else {
        dispatch(setAvailableRests(null));
        setGeneralError("No Restaurants found");
        console.log('blank');
      }
    })();
  };

  useEffect(() => {
    if (location && date && time) {
      dispatch(addRestForm({ location, date, time }));
      postRestFormData();
    }
  }, [location, date, time]);

  useEffect(() => {
    setTimeout(() => {
      setGeneralError("");
    }, 5000);
  }, [generalError]);

  const errorStyles = {
    width: "80vw",
    height: "50px",
    left: matches ? "" : "6rem",
    position: "fixed",
    borderRadius: "15px",
    padding: "4px 0",
    bottom: "1rem",
    backgroundColor: "#D84646",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "-2px 6px 15px 0px rgba(51,51,51,0.43)",
    "-webkit-box-shadow": "-2px 6px 15px 0px rgba(51,51,51,0.43)",
    "-moz-box-shadow": "-2px 6px 15px 0px rgba(51,51,51,0.43)",
    zIndex: "1000",
  };

  //declare lugha
  var lugha = JSON.parse(window.sessionStorage.getItem("lugha"));
console.log(time)
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {/* conditional layout for home screen */}
        {browserLocation.pathname === "/" ? (
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col md:flex-row rounded-xl lg:rounded-[30px] bg-[#201f21] max-w-screen-lg self-center m-auto mt-8 lg:mt-40 mb-24 lg:px-8 px-4 lg:w-[95%] xl:w-full">
              <div className="w-full h-full absolute px-4 left-0 ">
                <div className="relative w-full h-full">
                  <div className="absolute w-full bottom-0">
                    <img
                      alt="Night"
                      src={BottomPattern}
                      class="inset-0 h-full w-full object-contain hidden lg:flex"
                    />
                  </div>
                  <div className="absolute w-full top-0 ">
                    <img
                      alt="Night"
                      src={BottomPattern}
                      class="inset-0 h-full w-full object-contain -scale-y-100 -scale-x-100 lg:hidden"
                    />
                  </div>
                </div>
              </div>

              <img
                alt="Night"
                src={GreenLogo}
                class="inset-0 h-[125%] w-[60%] xl:h-[130%] xl:w-[60%] object-contain absolute -top-20  xl:-top-[100px] -left-28 xl:-left-32 hidden lg:flex"
              />

              <div className="w-full hidden lg:flex"></div>
              <div className="w-full z-[99]">
                <form action="#" class="my-2  py-8">
                  <div class="flex w-full border-2 my-4 rounded-md py-2 px-4 border-[#2F9F48] flex-col md:flex-row md:justify-between">
                    <div className="w-full mr-3">
                      
                      <LocationInput
                        placeholder={lugha.restaurantLocation}
                        options={locationOptions}
                        onchange={(e) => setLocation(e.target.value)}
                        value={location}
                        colored={true}
                      />
                    </div>
                  </div>
                  <div class="col-span-6 flex w-full border-2 my-4 rounded-md py-2 px-4 border-[#2F9F48] flex-col md:flex-row md:justify-between">
                    <div className="w-full mr-3 flex flex-col justify-start">
                      <DatePicker
                        placeholder={lugha.restaurantArrivalDate}
                        onchange={(value) =>
                          setDate(moment(value).format("YYYY-MM-DD"))
                        }
                        value={date}
                      />
                    </div>

                    <div className="w-full mr-3">
                      
                      <TimePicker
                        placeholder={lugha.restaurantArrivalTime}            
                        onchange={(value) => {
                          let date = new Date(value);
                          let formattedTime = date.toLocaleTimeString(undefined, {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          });
                          setTime(formattedTime);
                          setDisplayTime(value);
                        }}
                        value={displayTime}
                      />
                      
                      {/* <TimeInput
                        type={timeFocus}
                        placeholder={lugha.restaurantArrivalTime}
                        onfocus={() => {
                          setTimeFocus("time");
                        }}
                        onchange={(e) => setTime(e.target.value)}
                        value={time}
                        required={true}
                        colored={true}
                      /> */}
                    </div>
                  </div>
                  <div class="sm:flex sm:items-center sm:gap-4 sm:justify-end z-[9999999] w-full">
                    {location && date && time && (
                      <Submit
                        text={lugha.search}
                        iconName={faMagnifyingGlass}
                        pathTo="/restaurants"
                        action={() => {
                          dispatch(setHomeBg(0));
                          postRestFormData();
                        }}
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.restForm}>
            {/* Error div */}
            {generalError && <div style={errorStyles}>{generalError}</div>}

            <LocationInput
              // placeholder={lugha.restaurantLocation}
              options={locationOptions}
              onchange={(e) => setLocation(e.target.value)}
              value={location}
              required={true}
            />
          </div>
        )}
      </LocalizationProvider>
    </>
  );
}

export default RestForm;

const styles = {
  display: "flex",
  flexDirection: "row",
  width: "100vw",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "5%",
};

const errorStyles = {
  width: "80vw",
  height: "50px",
  position: "absolute",
  borderRadius: "5px",
  top: "0%",
  backgroundColor: "red",
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "1000",
};
