import './App.css';
import { Routes, Route, Link } from "react-router-dom";
import Home from './Screens/Home';
import BusListings from './Screens/BusListings';
import RestListings from './Screens/RestListings';
import HotelListings from './Screens/HotelListings';
import BookSeats from './Screens/BookSeats';
import BookDetails from './Screens/BookingDetails';
import TermsConditions from './Screens/Terms&Conditions';
import Privacy from './Screens/Privacy';
import TicketsScreen from './Screens/TicketsScreen';
import { Orodha } from './components/fx/list';
import EventListings from './Screens/EventListings';
import MatchListings from './Screens/MatchListings';
import EventView from './Screens/EventView';
import MatchView from './Screens/MatchView';
import EntertainmentListings from './Screens/EntertainmentListings';
import EntertainmentView from './Screens/EntertainmentView';
import TourListings from './Screens/TourListings';
import TourView from './Screens/TourView';
import HotelView from './Screens/HotelView';

function App() {
  if(!window.sessionStorage.getItem('regions')){
    Orodha();
  }
  
  return (
    <div className="App ">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/buses" element={<BusListings />} />
        <Route path="/tickets" element={<TicketsScreen />} />
        <Route path="/tickets/:reference" element={<TicketsScreen />} />
        <Route path="/restaurants" element={<RestListings />} />
        <Route path="/hotels" element={<HotelListings />} />
        <Route path="/hotel/:hotelID" element={<HotelView />} />
        <Route path="/events" element={<EventListings />} />
        <Route path="/matches" element={<MatchListings />} />
        <Route path="/event-details/:eventID" element={<EventView />} />
        <Route path="/match-details/:matchID" element={<MatchView />} />
        <Route path="/entertainment" element={<EntertainmentListings />} />
        <Route path="/hot-spot/:entertainmentID" element={<EntertainmentView />} />
        <Route path="/tours" element={<TourListings />} />
        <Route path="/tour-packages/:tourID" element={<TourView />} />
        <Route
          path="/book-seats/:plate/:routeID/:busDate"
          element={<BookSeats />}
        />
        <Route path="/booking/details" element={<BookDetails />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<Privacy />} />
      </Routes>
    </div>
  );
}

export default App;
